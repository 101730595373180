export default {
  primaryColor: '#30CCC2',
  secondaryColor: '#F9A02B',
  dangerColor: '#d35b47',

  green: '#30ccc2',
  pink: '#fcbdbf',
  white: '#ffffff',
  yellow: '#f9e770',
  blue: '#084c61',

  palette: {
    primary: {
      main: '#30CCC2',
    },
    secondary: {
      main: '#d35b47',
    },
    third: {
      main: '#F9A02B'
    }
  },

  noMargin: {
    margin: '0 0 5px 0!important',
  },

  marginBottom4:{
    marginBottom: 4,
  },
  marginBottom10:{
    marginBottom: 10,
  },
  marginBottom25:{
    marginBottom: 25,
  },
  marginBottom75:{
    marginBottom: 75,
  },

  marginTop4: {
    marginTop: 4,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop25: {
    marginTop: 25,
  },

  hMargin10: {
    marginLeft: 10,
    marginRight: 10,
  },

  wrapper: {
    width: '320px',
    margin: '0 auto'
  },

  root:{
    textAlign: 'center',
    maxWidth: 420,
    margin: '0 auto'
  },
	inlineBlock: {
		display: 'inline-block'
	},
	bigButton: {
		minWidth: 220,
		paddingTop: 15,
		paddingBottom: 15,
	},
	smallButton: {
		minWidth: 145,
		fontSize: '0.65rem',
		lineHeight: 'initial',
	},
	input: {
		minWidth: 270,
	},
	buttonWrapper: {
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
		top: 'calc(50% - 18px)',
		left: 'calc(50% - 18px)',
	},
	buttonProgressSmall: {
		position: 'absolute',
		top: 'calc(50% - 12px)',
		left: 'calc(50% - 12px)',
	},
	paragraph: {
		textAlign: "left",
		maxWidth: 300,
		margin: "0 auto",
	},
	btnIcon: {
		marginRight: 6,
  },
}