import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Routes from './routes'
import authService from './services/auth'
import Navbar from './containers/Navbar'
import './App.scss'
import './Theme.scss'
import { Layout } from 'antd'
import queryString from 'query-string'

import googleService from './services/google'
import usersService from './services/users'
import analytics from './services/analytics'

import PageLoading from './theme/PageLoading'

//import Onboarding from './components/Onboarding'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const { Content } = Layout

let USER_IDENTIFIED = false

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#30CCC2',
    },
    secondary: {
      main: '#ecb4b4', // old secondary #d35b47
    },
    // @ts-ignore
    third: {
      main: '#F9A02B'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
      },  
      // @ts-ignore
      outlined: {
        borderRadius: 30,
        borderColor: '#30CCC2',
      },  
      // flatSecondary: {
      //   borderColor: '#d35b47 !important',
      // },
      // raisedPrimary: {
      //   color: '#ffffff',
      // },
    },
    MuiInput: {
      input: {
        color: 'gray',
      },
      underline:{
        '&:after': {
          borderBottomColor: '#30CCC2',
        },
        '&:before': {
          borderBottomColor: '#30CCC2',
        },
        '&$focused:after':{
          borderBottomColor: '#30CCC2',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#30CCC2',
        },
      },
    },
    MuiFormLabel: {
      "root": {
        "&$focused": {
          "&$focused": {
            "color": "#30CCC2"
          }
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#30CCC2',
        borderRadius: 4,
      },
      inset: {
        marginLeft: 'calc((100% - 210px) / 2)',
        marginRight: 'calc((100% - 210px) / 2)',
      },
    },
  }
})

class App extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  state = {
    // pull in user data synchronously from localstorage before first render
    // otherwise we will render a <Redirect> (which is annoying to say the least..)
    user: authService.getUserData() ? authService.getUserData().user : null,
    session: authService.getUserData() ? authService.getUserData().session : null,

    loading: false
  }

  componentDidMount() {
    let viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute('content', 'width=375, initial-scale=0.9, maximum-scale=1.0, user-scalable=0')

    googleService.initialise()
    // @ts-ignore
    const params = queryString.parse(this.props.location.search)
    const token = params.token

    if (this.state.user) {
      this.loadTracking(this.state.user, this.state.session)
      authService.updateUser()
    }

    authService.watchUserData(this.handleUserChange)

    // this.context.router.history.listen(change => {
    //   console.log('change>', change)
    // })
  }

  componentWillUnmount() {
    authService.unwatchUserData(this.handleUserChange);
  }

  loadTracking(user, session) {
    if (!user) {
      return
    }

    if (user.gandalf) {
      return
    }

    if (!USER_IDENTIFIED) {
      analytics.identify(user.id, {
        email: user.email,
        name: user.name ? user.name : user.email,
        instagramUsername: user.instagram_username
      })

      USER_IDENTIFIED = true
    }

    // googleService.setUpUserTracking(user.id)
  }

  // arrow function to bind the function's `this` to this component
  // not using .bind(this) as we want the exact same reference to be able to unwatch
  handleUserChange = (userData) => {
    let user = null
    if (userData && userData.user) {
      user = userData.user
      // @ts-ignore
      this.loadTracking(user)
    }

    let session = null
    if (userData && userData.session) {
      session = userData.session
    }

    this.setState({
      user: user,
      session: session,
    });
  }

  render() {
    const { loading } = this.state
    if (loading) {
      return <PageLoading />
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Routes
          childProps={{
            user: this.state.user
          }} 
        />
      </MuiThemeProvider>
      // <MuiThemeProvider theme={theme}>
      //   <Layout className="layout"
      //     style={{
      //       /*background: '#F9F9F9',*/
      //       background: '#FFF',
      //       display: 'flex', flexDirection: 'column', //flexFlow: 'column nowrap',
      //       maxWidth: '500px', margin: '0 auto', minHeight: '100vh', maxHeight: '100vh'
      //     }}>

      //     <Content style={{
      //         flex: '1', paddingBottom: '55px',  // display: 'flex'
      //     }}>
      //       <Routes
      //         childProps={{
      //           user: this.state.user
      //         }} 
      //       />
      //     </Content>

      //     <Navbar user={this.state.user} {...this.props} />
      //   </Layout>
      // </MuiThemeProvider>
    );
  }
}

export default withRouter(App)
