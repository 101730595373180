import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// Images
import LOGO from '../../images/tremobooks-white-background.png'
import { HOME_LOCATION, LOGIN_LOCATION } from '../../common/constants'

export const LandingNavbar = (props) => {
  return (
    <div className='navbar navbar-expand-lg navbar-dark'>
      <div className='container'>
        {/* Logo */}
        <div className='navbar-left mr-4'>
          <div className='navbar-brand'>
            <Link to={HOME_LOCATION} >
              <img src={LOGO} />
            </Link>
          </div>
        </div>

        {/* Menu */}
        <div className='navbar-right'>
          <div className='nav nav-navbar nav-text-normal mr-auto'></div>

          <div className='d-flex align-items-center'>
            <Link
              to={LOGIN_LOCATION}
              className='btn btn-sm btn-link text-dark ml-lg-5 mr-2'
            >
              Login
            </Link>
          </div>
        </div>

        {/* <div className='navbar-mobile'>
          <div className='nav nav-navbar nav-text-normal mr-auto'>
            <Link to="/creators" className="nav-link">Creators</Link>
            <Link to="/brands" className="nav-link">Brands</Link>
            <Link to="/pricing" className="nav-link">Pricing</Link>
            <Link
              to={LOGIN_LOCATION}
              className="nav-link"
            >
              Login
            </Link>
          </div>

          <div className='d-flex align-items-right'>
            <Link
              to={LOGIN_LOCATION}
              className='btn btn-sm btn-link text-dark ml-lg-5 mr-2'
            >
              Login
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  )
}
