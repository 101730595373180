import React from 'react'
import { Footer } from '.'

export const RefundsPolicy = () => {
  return (
    <>
      <div className='header text-white' style={{ backgroundColor: '#ecb4b4' }}>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <h1>Tremo Limited Refunds Policy</h1>
              <p className='lead-2 opacity-90 mt-6'>
                Last update: December 6, 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='main-content'>
        <div className='section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-10 mx-auto'>
                <h4>General Information</h4>
                <p>
                  Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.
                </p>
                <p>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
                <p><b>Non-returnable items:</b> Gift cards.</p>

                <br />
                <p>
                  To complete your return, we require a receipt or proof of purchase.
                </p>
                <p>There are certain situations where only partial refunds are granted: (if applicable)</p>
                <p>Any item not in its original condition, different from the one advertised on our website, is damaged or missing parts for reasons not due to our error. </p>
                <p>Any item that is returned more than 30 days after delivery</p>

                <br />
                <h4>Refunds (if applicable)</h4>
                <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 24 hours.</p>
                
                <br />
                <h4>Late or missing refunds (if applicable)</h4>
                <p>If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted. If you’ve done all of this and you still have not received your refund yet, please contact us at <a href="mailto:contact@tremobooks.com">contact@tremobooks.com</a>.</p>

                <br />
                <p></p>Refunds/Exchanges for Photo Books - please email us at <a href="mailto: contact@tremobooks.com">contact@tremobooks.com</a> in order to discuss and process any refunds.

                <br />
                <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
                <p>Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</p>
                <p>If you are shipping an item over $75, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
