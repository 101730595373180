import { GOOGLE_TRACKING_ID } from '../constants'

export default class Google {

  static trackPage(page) {
    if (!window.gtag) {
      return console.warn('window.gtag is not defined')
    }

    window.gtag('config', GOOGLE_TRACKING_ID, {
      page_path: page, 
      page_title: document.title
    })
  }

  static setUpUserTracking(userId) {
    if(!userId) {
      return
    }
    /* Important: This cannot be any personally identifiable information, such as 
     * name or email */
    Google.set({
      'user_id': userId
    })
  }

  static purchase(purchaseData) {
    Google.event('purchase', purchaseData)
  }

	static event(eventName, eventData) {
		if (window.gtag) {
			window.gtag('event', eventName, eventData)
		}
	}

  static set(parameters) {
    if (window.gtag) {
      window.gtag('set', parameters)
    }
  }

	static initialise() {
    if(window.gtag) {
      return
    }

		let script = document.createElement('script')
		script.type = 'text/javascript'
		script.async = true
		script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TRACKING_ID}`
		document.head.appendChild(script)
		
		script = document.createElement('script')
		script.type = 'text/javascript'
		script.innerHTML = `
			window.dataLayer = window.dataLayer || [];
		  window.gtag = function(){
		  	dataLayer.push(arguments)
		  }
		  gtag('js', new Date());
		  gtag('config', '${GOOGLE_TRACKING_ID}');
		`
		document.head.appendChild(script)
	}
} 
