import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import React from 'react'

import { env, BUGSNAG_KEY } from '../constants';

// setup bugsnag

const bugsnagClient = bugsnag({
  apiKey: BUGSNAG_KEY,
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: env,
})
bugsnagClient.use(bugsnagReact, React)

export default bugsnagClient
