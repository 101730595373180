import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import JournalService from './JournalService'

import BABY_IMG from '../../images/illustrations/baby-rafiki.svg'
import { Button, CircularProgress, FormControl, FormHelperText } from '@material-ui/core'
import { UploadSingle } from '../upload'
import { HOME_LOCATION, LOGGED_IN_LOCATION, LOGIN_LOCATION } from '../../common/constants'
import { LogoCenter } from '../common'

export const QuestionEntry = (props) => {
  const [questionUuid, setQuestionUuid] = useState(null)
  const [question, setQuestion] = useState(null)
  const [caption, setCaption] = useState('')
  const [imagePath, setImagePath] = useState(null)
  const [imagePublicUrl, setImagePublicUrl] = useState(null)
  const [imageType, setImageType] = useState(null)
  const [imageCreatedAtOriginal, setImageCreatedAtOriginal] = useState(null)

  const [pageError, setPageError] = useState(null)
  const [error, setError] = useState(null)

  const [loadingAddJournalEntry, setLoadingAddJournalEntry] = useState(false)
  const [questionEntryAdded, setQuestionEntryAdded] = useState(false)
  
  useEffect(() => {
    const { uuid } = props.match.params
    
    if (!uuid) {
      setError(`Invalid link.`)
    }

    const loadQuestion = async () => {
      try {
        const { question } = await JournalService.getQuestionByUuid(uuid)

        setQuestionUuid(uuid)
        setQuestion(question.question)
        setQuestion(question)
      } catch (error) {
        if (error.message.toLowerCase().includes(`invalid question`)) {
          return setPageError(`Invalid link.`)
        }

        setPageError(error.message)
      }
    }

    loadQuestion()
  }, [])

  const addEntry = async () => {
    setLoadingAddJournalEntry(true)

    if (!caption || caption.length === 0) {
      setLoadingAddJournalEntry(false)
      return setError(`Please set a caption for you journal entry.`)
    }

    if (caption.length > 300) {
      setLoadingAddJournalEntry(false)
      return setError('Please keep your answer under 300 characters.')
    }

    if (!imagePath) {
      setLoadingAddJournalEntry(false)
      return setError(`Please upload an image to represent your journal entry.`)
    }

    try {
      await JournalService.addPageByQuestion({
        questionUuid, caption, imagePath, imagePublicUrl, imageType, imageCreatedAtOriginal
      })

      setError(null)
      setQuestionEntryAdded(true)
      setLoadingAddJournalEntry(false)
    } catch (error) {
      setQuestionEntryAdded(false)
      setLoadingAddJournalEntry(false)
      return setError(error.message)
    }
  }

  const renderError = () => {
    if (!pageError) {
      return null
    }

    return (
      <div className="alert alert-danger mt-4 mb-0 small" role="alert">
        {pageError}
      </div>
    )
  }

  const renderQuestionEntry = () => {
    if (pageError || questionEntryAdded) {
      return null
    }

    return (
      <>
        <h5 className='card-title'>{question}</h5>
        <FormControl error={!!error} style={{ width: '100%'}}>
          <textarea
            placeholder="Write your answer here."
            value={caption}
            onChange={(e) => setCaption(e.target.value)} 
            className="mb-3 p-2"
            style={{ height: '140px' }}
          />
          <FormHelperText>{error}</FormHelperText>
          {!!error && <div className="mt-3" />}
        </FormControl>
        <UploadSingle onUploadCompleted={({ pathName, publicUrl, type, createdAtOriginal }) => {
          setImagePath(pathName)
          setImagePublicUrl(publicUrl)
          setImageType(type)
          setImageCreatedAtOriginal(createdAtOriginal)
        }}/>

        {imagePublicUrl && <img src={imagePublicUrl} className="mt-3" />}

        <Button 
          variant="outlined"
          color="primary"
          className={['w-100', 'mt-5'].join(' ')}
          onClick={addEntry}
          style={{ height: '40px' }}
        >
          {loadingAddJournalEntry ? <CircularProgress color="primary" size={24} /> : `Add Journal Entry`}
        </Button>
      </>
    )
  }

  const renderQuestionEntryAdded = () => {
    if (pageError || !questionEntryAdded) {
      return null
    }

    return (
      <>
        <h5 className='card-title'>You entry has been added</h5>
        <br />
        <Button
          variant="outlined"
          color="primary"
          style={{ width: '100%', marginBottom: '25px', height: '40px' }}
          onClick={() => props.history.push(LOGGED_IN_LOCATION)}
        >
          View Journal
        </Button>
      </>
    )
  }

  return (
    <div className='container d-flex h-100 justify-content-center'>
      <div className="row justify-content-center align-self-center">
        <LogoCenter />

        <div className='col-12 bg-white rounded shadow-7 p-6 w-300 h-100 mt-5'>
          <div className='card d-block'>
            <div className='card-img-top' style={{ width: '150px', margin: '0 auto' }}>
              <img
                src={BABY_IMG}
                alt='Baby Journal Book'
              />
            </div>

            <div className='card-body'>
              {renderError()}
              {renderQuestionEntry()}
              {renderQuestionEntryAdded()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}