import React from 'react'
import { Footer } from '.'

export const ShippingPolicy = () => {
  return (
    <>
      <div className='header text-white' style={{ backgroundColor: '#ecb4b4' }}>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <h1>Tremo Limited Shipping Policy</h1>
              <p className='lead-2 opacity-90 mt-6'>
                Last update: December 6, 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='main-content'>
        <div className='section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-10 mx-auto'>
                <h4>General Information</h4>
                <p>
                  At Tremo Books Ltd., our goal is to offer you the best shipping options, no matter where you live. Every day, we deliver to hundreds of customers across the world, ensuring that we provide the very highest levels of responsiveness to you at all times.
                </p>
                <p><b>The time frame for order delivery is divided the following way:</b></p>
                <p>
                  Processing time for Photo Books: Order verification, processing, checking and packaging. All orders are sent out from our warehouse within 1-5 business days after the order is placed.
                </p>
                <p>Shipping time for Photo Books: This refers to the time it takes for items to be shipped from our warehouse to the destination. International delivery usually takes about 10–30 business days. At this point, we do not provide a tracking no. for international orders.</p>
                <p>Tracking number is available for all the UK, US, Germany & France orders on our product, Tremo Books and is granted by our carrier. It will be provided to you, via email, after your order is processed.</p>
                <p>Tracking number is not available in rest of the world, as of yet.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
