import React, { useEffect, useState } from 'react'
import LOGO from "../../images/logo-transparent.png"
import NEW_MESSAGE_IMG from '../../images/illustrations/new-message-bro.svg'
import { LogoCenter } from '../common'

export const SignupCompleted = (props) => {
  const { user } = props

  return (
    <div className='container d-flex h-100 justify-content-center'>
      <div className="row justify-content-center align-self-center">
        <LogoCenter />
        
        <div className='col-12 bg-white rounded shadow-7 p-6 w-300 h-100 mt-5'>
          <div className='card d-block'>
            <div className='card-img-top'>
              <img
                src={NEW_MESSAGE_IMG}
                alt='Baby Journal Book'
                style={{ width: '150px', marginLeft: '93px' }}
              />
            </div>

            <div className='card-body'>
              <h5 className='card-title'>Add your first journal entry</h5>
              <p>
                We just texted you at {user.phone_number}. Click on the link included to add {user.childName}'s first journal entry.
              </p>
              <p>
                Keep an eye out for your daily Tremo question and enjoy building your child's journal.
              </p>
              <p>
                If you used the wrong phone number or if you have any other questions please email us at {' '} 
                <a
                  href="mailto:contact@tremobooks.com"
                  style={{ cursor: 'pointer', color: '#0000EE' }}
                >
                  contact@tremobooks.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
