import React, { useRef, useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { Button, CircularProgress, ListItem } from '@material-ui/core'
import UploadIcon from '@material-ui/icons/CloudUpload'
import filesService from '../../services/files'

const uploadFile = async (file): Promise<{
  pathName: string,
  publicUrl: string,
  type: string,
  createdAtOriginal: string
}> => {
  const { pathName, signedRequestUrl, publicUrl } = await filesService.getSignedUrl(file.name, file.type)

  await axios({
    url: signedRequestUrl, 
    method: 'PUT',
    data: file
  })

  return {
    pathName, publicUrl, type: file.type, createdAtOriginal: moment(file.lastModified).format('YYYY-MM-DD HH:mm:ss')
  }
}

/**
 * This component uploads a photo to S3
 */
export const UploadSingle = ({ onUploadCompleted }) => {
  const uploadInputRef = useRef(null)

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const uploadFiles = async (event) => {
    event.persist()
    setLoading(true)
  
    const { target: { files } } = event
  
    if (!files) {
      return null
    }
  
    try {
      let filesArr = Array.from(files)
  
      const chunks: any = filesArr.reduce((a: any, c) => {
        const tailArr = a[a.length - 1]
        if (tailArr.length < 5) {
          tailArr.push(c)
        } else {
          a.push([c])
        }
        return a
      }, [[]])
  
      let filesData
      for (const chunk of chunks) {
        filesData = await Promise.all(chunk.map(uploadFile))
      }
      
      setLoading(false)
      setError(null)

      // this returns the public url
      onUploadCompleted({
        pathName: filesData?.[0]?.pathName,
        publicUrl: filesData?.[0]?.publicUrl,
        type: filesData?.[0]?.type,
        createdAtOriginal: filesData?.[0]?.createdAtOriginal,
      })
    } catch (error) {
      console.log('Error', error)
      setLoading(false)
      setError(`Something went wrong. We accept only jpg and png files. Please try again.`)
    }
  }

  return (
    <>
      <input
        ref={uploadInputRef}
        accept="image/png, image/jpeg"
        id="fileUpload"
        type="file"
        onChange={uploadFiles}
        // disabled={disabled}
        style={{ display: 'none' }}
      />
      <Button 
        variant="outlined"
        color="secondary"
        className={['w-100'].join(' ')}
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
        style={{ height: '40px' }}
      >
        {loading ? <CircularProgress color="secondary" size={24} /> : <><UploadIcon className="mr-1" />{`Upload photo`}</>}
      </Button>

      {error && <div className="alert alert-danger mt-4 mb-0 small" role="alert">
        {error}
      </div>}
    </>
  )
}