import React from "react";
import { Route } from "react-router-dom";

// export default ({ component: Component, props: childProps, children, ...rest }) => (
//   <Route
//     {...rest}
//     render={props => <Component {...props} {...childProps} children={children} />}
//   />
// );


export default class RouteWrapper extends React.Component {
	checkLocation(nextProps) {
    const isDefined = v => {
      return typeof v !== 'undefined'
    }
    if (isDefined(this.props) && isDefined(this.props.location) && isDefined(this.props.location.pathname)) {
      if (!isDefined(nextProps) || (isDefined(nextProps) && isDefined(nextProps.location) && (this.props.location.pathname !== nextProps.location.pathname || this.props.location.search !== nextProps.location.search))) {
        const o = nextProps || this.props
        this.props.trackPage(o.location.pathname, o.location.search)
      }
    }
  }

  componentDidMount() {
    this.checkLocation()
  }

  componentWillReceiveProps(nextProps) {
    this.checkLocation(nextProps)
  }

	render() {
		const { component: Component, props: childProps, children, ...rest } = this.props
		return (<Route
						{...rest}
						render={props => <Component {...props} {...childProps} children={children} />}
					 />)
	}
}