import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import authService from '../services/auth'
import { HOME_LOCATION } from '../common/constants'

// export default ({ component: Component, props: childProps, location, children, ...rest }) => {
//   const { user } = childProps

//   if (!user) {
//     // pass to login with the current location state, allowing us to redirect back
//     return (
//       <Redirect to={{ pathname: '/login', state: { redirectToLocation: location } }} />
//     )
//   }

//   return (
//     <Route
//       {...rest}
//       render={props => <Component {...props} {...childProps} children={children} />}
//     />
//   )
// }

export default class AuthenticatedRoute extends React.Component {
  checkLocation(nextProps) {
    const isDefined = (v) => {
      return typeof v !== 'undefined'
    }
    if (
      isDefined(this.props) &&
      isDefined(this.props.location) &&
      isDefined(this.props.location.pathname)
    ) {
      if (
        !isDefined(nextProps) ||
        (isDefined(nextProps) &&
          isDefined(nextProps.location) &&
          (this.props.location.pathname !== nextProps.location.pathname ||
            this.props.location.search !== nextProps.location.search))
      ) {
        const o = nextProps || this.props
        this.props.trackPage(o.location.pathname, o.location.search)
      }
    }
  }

  componentDidMount() {
    this.checkLocation()
  }

  componentWillReceiveProps(nextProps) {
    this.checkLocation(nextProps)
  }

  render() {
    const {
      component: Component,
      props: childProps,
      location,
      children,
      ...rest
    } = this.props
    const { user } = childProps

    if (!user) {
      // pass to login with the current location state, allowing us to redirect back
      return (
        <Redirect
          to={{
            pathname: HOME_LOCATION,
            state: { redirectToLocation: location },
          }}
        />
      )
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <Component {...props} {...childProps} children={children} />
        )}
      />
    )
  }
}

// class AuthenticatedRoute extends React.Component {
//   state = {
//     token: null,
//     redirectTo: null
//   }

//   componentDidMount() {
//     const params = queryString.parse(this.props.location.search)
//     const token = params.token

//     if (!token) {
//       return null
//     }

//     this.setState({
//       token
//     })

//     authService.loginWithToken(token).then(data => {
//       console.log('logged in', this.props.location.pathname)
//       this.setState({
//         token, redirectTo: this.props.location.pathname
//       })
//     }).catch(err => {
//       console.log('error', err)
//       this.setState({
//         redirectTo: '/login'
//       })
//     })
//   }

//   render() {
//     const { component: Component, props: childProps, location, children, ...rest } = this.props
//     const { user } = childProps
//     const { token, redirectTo } = this.state
//     // console.log('wtf??', token)
//     if (token) {
//       // console.log('here', redirectTo)
//       if (!redirectTo) {
//         return null
//       } else {
//         // console.log('redirect to', redirectTo)
//         return <Redirect to={{ pathname: redirectTo }} />
//       }
//     }

//     if (!user) {
//       // pass to login with the current location state, allowing us to redirect back
//       return (
//         <Redirect to={{ pathname: '/login', state: { redirectToLocation: location } }} />
//       )
//     }

//     return (
//       <Route
//         {...rest}
//         render={props => <Component {...props} {...childProps} children={children} />}
//       />
//     )
//   }
// }

// export default AuthenticatedRoute
