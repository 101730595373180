import React from 'react'
import { Footer } from './Footer'

export const PrivacyPolicy = () => {
  return (
    <>
      <div className='header text-white' style={{ backgroundColor: '#ecb4b4' }}>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <h1>Tremo Limited Privacy Policy</h1>
              <p className='lead-2 opacity-90 mt-6'>
                Last update: December 6, 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='main-content'>
        <div className='section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-10 mx-auto'>
                <h4>General Information</h4>
                <p>
                  This Privacy Policy has been created for the website you are using (our “Service”) by Tremo Limited (“Tremo”, “we,” “us” or “our”).
                </p>
                <p>
                  With this Privacy Policy, we would like to explain how we collect and use your personal data and what rights you have under data protection law, including the General Data Protection Regulation of the European Union (“GDPR”).
                </p>
                <p>
                  By using our Service, you agree to our collection and use of personal data in accordance with this policy.
                </p>

                <br />
                <h4>Who is responsible for your personal data?</h4>
                <p>
                  Tremo Limited is responsible for your personal data. You can reach us via email at <a href="mailto:contact@tremobooks.com">contact@tremobooks.com</a> or via post at: <br /><br />
                  Tremo Limited <br />
                  1a Accommodation Road <br />
                  London, United Kingdom <br />
                  NW11 8ED
                </p>

                <br />
                <h4>When do we collect your personal data?</h4>
                <p>
                  We collect your personal data when you place an order on our website. We also collect personal data from you when you communicate with us via email or subscribe to our mailing list. In addition, we collect your personal data when you visit our website, provide us with your Instagram username, participate in our contests or surveys and/or register for an account on our Service.
                </p>

                <br />
                <h4>Cookies and Other Tools</h4>
                <p>
                  To help us operate our Service, we use “cookies,” which are small files that we store on your browser or device that identify you as a unique user and gather data about you. Cookies allow us, among other things, to recognise your browser or device, tell us how and when you visited pages and used features in our Service and make your experience using our Service more personal.
                </p>
                <p>
                  If you would prefer for us not to use cookies while you use our Service, you can set your browser to not accept cookies or you can delete some or all of the cookies on your device. To do so, please follow the applicable help file directions available via your browser. Please note, however, that if you decide to reject or to delete cookies you may have difficulty using some of the features of our Service.
                </p>
                <p>
                  In addition to cookies, we may also use other common data gathering tools,such as web beacons and embedded web links.
                </p>

                <br />
                <h4>How we collect and use your data?</h4>
                <p>
                  We may ask you to provide us with certain personal data that we may use to contact or to identify you, and help make your experience using our Service more personal. This personal data may include, but is not limited to, your name, your address, your email address, your telephone number and other contact information, photos, data required in order for us to process payments, including credit/debit card numbers, and other personal information, such as your preferences, passwords for our password-protected services and other similar personal data.
                </p>
                <p>
                  We do not knowingly collect personal data from, or target our service at, people under the age of 18.
                </p>
                <p>
                  We may collect anonymous information, such as the language you use, your regional location and your device identifier, from you and from all of the users of our Service, regardless of how you use our Service. We do not use such anonymous information in connection with your identity or to identify you.
                </p>
                <p>
                  We will only use your personal data when the law allows us to.
                  We will normally use your personal data only where:
                </p>
                <ul>
                  <li>
                    We need to perform the contract we have with you or with a
                    customer of ours;
                  </li>
                  <li>
                    It is necessary for our legitimate interests, or those of a
                    third party, and your interests and fundamental rights do
                    not override those interests; or
                  </li>
                  <li>
                    We need to comply with a legal/regulatory requirement.
                  </li>
                </ul>
                <p>
                  Generally, we do not rely on consent as a legal basis for
                  processing your personal data other than in relation to
                  sending you marketing or third-party direct marketing
                  communications via email or text message. You have the right
                  to withdraw consent to marketing at any time by contacting us
                  or by clicking ‘unsubscribe’ in any communication you receive
                  from us.
                </p>

                <br />
                <h4>How will we communicate with you?</h4>
                <p>
                  We may use your personal data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
                </p>

                <br />
                <h4>Why do we process your personal data?</h4>
                <p>We process your personal data in order to:</p>
                <ul>
                  <li>
                    Performance of a contract with you or with a customer or
                    ours;
                  </li>
                  <li>Process and deliver a service or order;</li>
                  <li>
                    Process the payments that you make through our Service;
                  </li>
                  <li>Manage your relationship with us;</li>
                  <li>Enhance our marketing efforts;</li>
                  <li>Operate our website;</li>
                  <li>Operate our software;</li>
                  <li>
                    Use data analytics to improve our website,
                    products/services, marketing, customer relationships and
                    experiences;
                  </li>
                  <li>
                    Make suggestions or recommendations to you about goods or
                    services that may be of interest to you; and
                  </li>
                  <li>Comply with our record keeping and other obligations.</li>
                </ul>
                <p>
                  In addition, we use anonymous information to track lots of
                  information relating to the use of our website. For example,
                  we track the total number of users on our website, the number
                  of users on each page of our website, which countries the
                  users of our website are located in, and other aspects of
                  users’ behaviour on our website.
                </p>

                <br />
                <h4>What rights do we have to process your personal data?</h4>
                <p>
                  Our rights to process your personal data depends on the
                  reason(s) or purpose(s) we have for processing of your
                  personal data. When we process your personal data, we do so
                  for one or more of the following reasons/purposes:
                </p>
                <ul>
                  <li>
                    To meet our obligations under (1) your customer contract, or
                    in order to take steps prior to entering into such contract
                    with you, or (2) our contracts with our customers;
                  </li>
                  <li>
                    To comply with a legal obligation, such as record-keeping or
                    other regulatory obligations;
                  </li>
                  <li>
                    To protect your vital interests or the vital interests of
                    another natural person; or
                  </li>
                  <li>
                    To further our legitimate interests or those of any third
                    party recipients that receive your personal data, provided
                    that such interests are not outweighed by your interests or
                    rights.
                  </li>
                </ul>
                <p>
                  Our legitimate interests include our legitimate commercial
                  interests. In furtherance of such interests, we may process
                  your personal data to (1) comply with applicable laws and
                  regulations, (2) record-keep effectively, (3) engage in
                  marketing or branding activities (not including our general
                  marketing emails, which we only send to those who have given
                  us their active consent), (4) carry out our contractual
                  obligations, including our customer contract with you or with
                  our third party customers, (5) manage our relationship with
                  you, including, but not limited to, sending you
                  customer-specific confirmations and/or reminders regarding
                  your account with us, and (6) improve our business processes.{' '}
                </p>

                <br />
                <h4>Will we share your personal data with anyone?</h4>
                <p>
                  In order for us to comply with our legal obligations, to
                  improve our Service, to provide the Service on our behalf or
                  to assist us in analyzing how our Service is used, we may
                  share some of your personal data with third party service
                  providers. Such service providers may include:
                </p>
                <ul>
                  <li>Governmental authorities and agencies;</li>
                  <li>
                    IT, logistics, manufacturing, delivery, communication and
                    accounting service providers; and
                  </li>
                  <li>
                    Other third parties in connection with the legal claims.
                  </li>
                </ul>
                <p>
                  In addition, we may share anonymous data with our affiliates,
                  agents and business partners, and to other third parties, for
                  business purposes. One such third party is Google Analytics,
                  which collects, monitors and analyses this type of information
                  in order to increase our Service's functionality.
                </p>

                <br />
                <h4>
                  Will we transfer your personal data to any countries outside
                  the European Union?
                </h4>
                <p>
                  Our company in based in the United Kingdom and our operations
                  are located in the United Kingdom. In accordance
                  with GDPR, we may transfer your personal data to countries
                  outside the European Union (so-called third countries) if
                  required for a legitimate business purpose.
                </p>

                <br />
                <h4>How long will we retain your data for?</h4>
                <p>
                  If you have not logged into your account for three years, your
                  personal data and any photos may either be erased, anonymised,
                  or transferred to an archive used for tax, anti-fraud,
                  historical, scientific or statistical purposes and other
                  similar purposes.
                </p>

                <br />
                <h4>How do we protect your personal data?</h4>
                <p>
                  In order to protect your personal data and keep it
                  confidential, we have adopted a data protection policy and
                  implemented various security measures in accordance with GDPR,
                  including, but not limited to, the use 256-bit SSL encryption,
                  which means your interaction with us through our website is
                  private, the ability to restore and access personal data in
                  the event of a technical issue and a process for regularly
                  evaluating our security measures.
                </p>

                <br />
                <h4>What rights do you have under GDPR?</h4>
                <p>
                  Under GDPR, If you are an EU citizen or non-EU national
                  residing in the EU, subject to certain conditions, you have a
                  number of rights relating to your personal data. Your rights
                  are:
                </p>
                <ul>
                  <li>The right of access to your data that we hold;</li>
                  <li>The right of data portability;</li>
                  <li>
                    The right to have data held by us corrected if it is
                    incorrect;
                  </li>
                  <li>
                    The right to have us stop processing, or to object to us
                    processing, your data;
                  </li>
                  <li>The right to have us erase your data; and</li>
                  <li>
                    The right to lodge a complaint with a competent data
                    protection supervisory authority.
                  </li>
                </ul>
                <p>
                  To discuss any of these rights, please contact us via post at:
                </p>
                <p>
                  Tremo Limited<br />
                  1a Accommodation Road<br />
                  London, United Kingdom<br />
                  NW11 8ED<br />
                </p>

                <br />
                <h4>Links To Other Sites</h4>
                <p>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party's site. Please note that you
                  do so entirely at your own risk as we have no control over or
                  responsibility for such websites. We strongly advise you to
                  review the Privacy Policy of every site you visit. We do not
                  endorse or make any representations about such websites or any
                  material found on them. We have no control over, and assume no
                  responsibility for the content, privacy policies or practices
                  of any third party sites or services. We accept no
                  responsibility for any loss or damage that may arise from your
                  use of any such third party website.
                </p>

                <br />
                <h4>Updates to this Privacy Policy</h4>
                <p>
                  This Privacy Policy is effective as of the date at the top of
                  this webpage. We reserve the right to make changes to this
                  privacy policy at any time by giving notice to you on this
                  page. Such notice will be deemed given and received by us
                  changing the last updated date at the top of this page. We
                  strongly recommended that you check this webpage often,
                  referring to the date of the last modification listed at the
                  top. The changes will take effect as soon as they are posted
                  as part of our Service.
                </p>

                <br />
                <h4>Contact Us</h4>
                <p>If you have any questions, please contact us at <a href="mailto:contact@tremobooks.com">contact@tremobooks.com</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
