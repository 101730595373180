import React from 'react'
import PropTypes from 'prop-types'

import { Spin } from 'antd'

import MuiCircularProgress from '@material-ui/core/CircularProgress'

import styles from '../styles/common'

class PageLoading extends React.Component {
  static propTypes = {
    style: PropTypes.object, 
  }

  render() {
    return (
      <div style={{ ...styles.wrapper, marginTop: '100px', textAlign: 'center', ...this.props.style }} className="pageLoadingWrapper">
        <MuiCircularProgress
          className="pageLoadingGraphic"
          size={70}
        />
      </div>
    )
  }
}

export default PageLoading