import DateFnsUtils from '@date-io/date-fns'
import MuiCheckbox from "@material-ui/core/Checkbox"
import { Button } from '@material-ui/core'
import MuiCircularProgress from "@material-ui/core/CircularProgress"
import MuiFormControl from "@material-ui/core/FormControl"
import MuiFormControlLabel from "@material-ui/core/FormControlLabel"
import MuiTextField from "@material-ui/core/TextField"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LOGGED_IN_LOCATION, LOGIN_LOCATION, SIGN_UP_ADD_CARD_LOCATION, SIGN_UP_COMPLETED_LOCATION } from '../../common/constants'
import LOGO from "../../images/logo-transparent.png"
import usersService from '../../services/users'
import './Signup.scss'
import { LogoCenter } from '../common'

const Header = () => {
  return (
    <div className="normalTitleWrapper" style={{ textAlign: "center" }}>
      <LogoCenter />
      <br />
      <div className="contentTitle">Start your free trial</div>
      <p>Please add your details to start using our SMS reminders and digital journal service.</p>
      <br />
    </div>
  )
}

const onKeyPress = (e) => {
  if (e.key === "Enter") {
    console.log('ENTER')
  }
}

export const SignupJournal = (props) => {
  useEffect(() => {
    console.log(props)
    const { user } = props

    if (user) {
      return props.history.push(LOGGED_IN_LOCATION)
    }

    // on mount listen to enter
    window.document.body.addEventListener("keypress", onKeyPress)

    // on unmount remove listener
    return () => window.document.body.removeEventListener("keypress", onKeyPress)
  }, [])

  const [ registerLoading, setRegisterLoading ] = useState(false)

  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ phoneNumber, setPhoneNumber ] = useState('')
  const [ childName, setChildName ] = useState('')
  const [ childBirthday, setChildBirthday ] = useState(null)
  const [ password, setPassword ] = useState('')
  const [ agreeTerms, setAgreeTerms ] = useState(false)
  const [ optinMarketingEmails, setOptinMarketingEmails ] = useState(false)

  const [ error, setError ] = useState(null)

  const register = async () => {
    // make sure terms and conditions are agreed
    if (!agreeTerms) {
      return setError(`Please agree to terms and conditions.`)
    }

    // 1. set loading state to true
    // 2. validate input
    // 3. make api request
    // 4. redirect to next page
    setRegisterLoading(true)
    setError(null)

    let data
    try {
      data = await usersService.register({
        name, email, phoneNumber, childName, childBirthday, password, optinMarketingEmails
      })

      setRegisterLoading(false)
      setError(null)

      props.history.push(SIGN_UP_ADD_CARD_LOCATION)
    } catch (error) {
      setRegisterLoading(false)
      setError(error.message)
    }
  }

  return (
    <>
      <div className="contentWrapper">
        <Header />
        <div>
          <MuiFormControl error={!!error} fullWidth>
            <MuiTextField
              fullWidth
              id="name"
              label={'Full Name'}
              value={name}
              placeholder={`Anne Thompson`}
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <MuiTextField
              fullWidth
              id="email"
              label={'Email'}
              value={email}
              placeholder={'anne@example.com'}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <MuiTextField
              fullWidth
              id="phoneNumber"
              label={'Phone Number'}
              value={phoneNumber}
              placeholder={'e.g. +447450244008'}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <p style={{ fontSize: '12px' }}>*Please include the country code prefix e.g. +44</p>
            <p style={{ fontSize: '12px' }}>*We will text you on this phone number. Please double check it is correct.</p>
            <MuiTextField
              fullWidth
              id="childName"
              label={'Your Son or Daughter First Name'}
              value={childName}
              placeholder={`Olivia`}
              onChange={(e) => setChildName(e.target.value)}
            />
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // disableToolbar
                // variant="inline"
                margin="normal"
                id="date-picker-dialog"
                label="Your Son or Daughter Date of Birth"
                format="dd/MM/yyyy"
                value={childBirthday}
                // helperText={<p style={{ color: 'red'}}>ERROR</p>}
                onChange={(date) => setChildBirthday(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <br />
            <MuiTextField
              fullWidth
              id="password"
              label={'Password'}
              type="password"
              value={password}
              placeholder={`At least 6 characters`}
              onChange={(e) => setPassword(e.target.value)}
            />
          </MuiFormControl>

          <MuiFormControl style={{ marginTop: "25px" }}>
            <MuiFormControlLabel
              style={{ height: 0 }}
              control={
                <MuiCheckbox
                  color="primary"
                  checked={agreeTerms}
                  onChange={() => setAgreeTerms(!agreeTerms)}
                />
              }
              label={
                <span style={{ fontSize: '12px', color: "#969696" }}>
                  {'I agree to the'}
                  &nbsp;
                  <a
                    href="https://tremobooks.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Privacy Policy'}
                  </a>
                  &nbsp; & &nbsp;
                  <a
                    href="https://tremobooks.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Terms of Use'}
                  </a>
                </span>
              }
            />
          </MuiFormControl>

          <MuiFormControl style={{ marginTop: "25px" }}>
            <MuiFormControlLabel
              style={{ height: 0 }}
              control={
                <MuiCheckbox
                  color="primary"
                  checked={optinMarketingEmails}
                  onChange={() => setOptinMarketingEmails(!optinMarketingEmails)}
                />
              }
              label={
                <span style={{ fontSize: '12px', color: "#969696" }}>
                  {`I want discounts, special offers and newsletters.`}
                </span>
              }
            />
          </MuiFormControl>
        </div>
        
        {error && <div className="alert alert-danger mt-4 mb-0" role="alert">
          {error}
        </div>}

        <div style={{ marginTop: "25px", marginBottom: "25px" }}>
          <Button 
            variant="outlined"
            color="primary"
            onClick={() => register()}
            disabled={!!registerLoading}
            style={{ width: '100%', height: '40px' }}
          >
            {registerLoading ? <MuiCircularProgress size={20} /> : "Alright I'm ready, let's do this"}
          </Button>
        </div>

        <div className="loginComponentWrapper">
          <p style={{ textAlign: "center" }}>
            Have an account already? <Link to={LOGIN_LOCATION}>Login</Link>
          </p>
        </div>
      </div>
    </>
  )
}