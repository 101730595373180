import { Button, CircularProgress } from '@material-ui/core'
import { Icon } from "antd"
import moment from 'moment'
import React, { useState } from 'react'
import StripeCheckout from "react-stripe-checkout"
import { SIGN_UP_COMPLETED_LOCATION } from '../../common/constants'
import { STRIPE_KEY } from '../../constants'
import BABY_IMG from '../../images/illustrations/baby-rafiki.svg'
import SECURE_PAYMENT_IMG from '../../images/secure-stripe-payment.png'
import ordersService from '../../services/orders'
import { LogoCenter } from '../common'
import PricingService from '../pricing/PricingService'

/**
 * Example: https://lucid.app/
 * 
 * https://growthhackers.com/questions/should-i-ask-for-credit-cards-during-the-registration-process
 * https://growthhackers.com/questions/should-i-ask-for-credit-cards-during-the-registration-process?_ga=2.155414716.669953341.1606988793-943809817.1606988793#comment-60553
 */
export const SignupAddCard = (props) => {
  const { user } = props
  const countryCode = user.country

  console.log('@CountryCode', countryCode)

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const onStripeSubscription = async (stripeData) => {
    setLoading(true)

    try {
      await ordersService.subscribeWithStripe(stripeData)
      props.history.push(SIGN_UP_COMPLETED_LOCATION)
    } catch (error) {
      setError(error.message)
    }

    setLoading(false)
  }

  return (
    <div className='container d-flex h-100 justify-content-center'>
      <div className="row justify-content-center align-self-center">
        <LogoCenter />
        
        <div className='col-12 bg-white rounded shadow-7 p-6 w-300 h-100 mt-5'>
          <div className='card d-block'>
            <div className='card-img-top' style={{ width: '150px', margin: '0 auto' }}>
              <img
                src={BABY_IMG}
                alt='Baby Journal Book'
              />
            </div>

            <div className='card-body'>
              <h5 className='card-title'>Add a payment method for after your trial ends</h5>
              <p>
                Today you will be charged {PricingService.getCurrencySymbolByCountry(countryCode)}0.00. You may cancel your 14 day free trial at any time by emailing us at <a href="mailto:contact@tremobooks.com" style={{ cursor: 'pointer', color: '#0000EE' }}>contact@tremobooks.com</a>.
              </p>
              <p>
                If you do not cancel during your free trial, you will be charged {PricingService.formatPriceByCountry(PricingService.getJournalPriceCents(countryCode), countryCode)}/month for your subscription on {moment().add(14, 'days').format('LL')}. Your subscription will automatically renew until you cancel it.
              </p>
              <p>If you have a discount code just email us after sign up and we'll apply to your subscription.</p>

              {error && <div className="alert alert-danger mt-4 mb-0" role="alert">
                {error}
              </div>}

              <div className="mt-3" style={{ width: '300px', margin: '0 auto' }}>
                <StripeCheckout
                  token={onStripeSubscription}
                  stripeKey={STRIPE_KEY}
                  name="Tremo Journal" // the pop-in header title
                  description="Secured by Stripe" // the pop-in header subtitle
                  email={user.email}
                >
                  <Button 
                    variant="outlined"
                    color="primary"
                    style={{ width: '100%', height: '40px' }}
                  >
                    {loading ? <CircularProgress size={20} /> : <><Icon type="credit-card" style={{ height: '16px', marginRight: '5px' }}/>Start 14 Day Free Trial</>}
                  </Button>
                </StripeCheckout>
              </div>

              <div className="mt-3" style={{ width: '200px', margin: '0 auto' }}>
                <img
                  src={SECURE_PAYMENT_IMG}
                  alt='Baby Journal Book'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}