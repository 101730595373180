import React from 'react'
import { Footer } from '.'

export const TermsPolicy = () => {
  return (
    <>
      <div className='header text-white' style={{ backgroundColor: '#ecb4b4' }}>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <h1>Tremo Limited Terms of Use</h1>
              <p className='lead-2 opacity-90 mt-6'>
                Last update: December 6, 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='main-content'>
        <div className='section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-10 mx-auto'>
                <h4>General Information</h4>
                <p>
                  We, at Tremo Limited (“Tremo”, “we” or “us”), have tried to make our terms of use as simple to understand as possible. If you have any thoughts on how we can improve these terms, please contact us at <a href="mailto:contact@tremobooks.com">contact@tremobooks.com</a>.
                </p>
                <p>
                  By using our website, located at <a href="https://tremobooks.com">tremobooks.com</a> (including all sub-domains, this “Site”), which is a service of Tremo, you are agreeing to be bound by the following terms and conditions set forth in these Terms of Use (the “Terms”) and Tremo’s Privacy Policy (collectively, the “Terms and Conditions”). If you do not agree to the Terms and Conditions, you may not, and please do not, use this Site.
                </p>

                <br />
                <h4>Notification of Changes</h4>
                <p>
                  We may change the terms of these Terms from time to time by
                  posting changes online and updating the last updated date. You
                  agree that it is your responsibility to review the Terms and
                  Conditions regularly. Your continued use of the Site after
                  such changes are posted will mean that you agree to be legally
                  bound by the modified Terms.
                </p>

                <br />
                <h4>Eligibility</h4>
                <p>
                  You represent and warrant that you are at least 18 years old
                  and that, if acting on behalf of a company/business or other
                  legal entity, you have the authority to bind that company or
                  other legal entity to the Terms and Conditions.
                </p>
                <p>
                  Tremo Limited retains the right to terminate or change the
                  service for any reason at any time without notice.
                </p>

                <br />
                <h4>Use of this Site</h4>
                <p>
                  You will not use this Site for any illegal or unauthorised
                  purpose and will comply with all applicable laws regarding
                  online conduct and content, such as libel law.
                </p>
                <p>
                  You will not threaten, intimidate, harass or otherwise act
                  inappropriately toward any other user of the Site.
                </p>
                <p>
                  You are solely responsible for your behaviour, conduct and
                  activities on the Site and for ensuring that any and all of
                  the data, text, information, usernames, graphics, images,
                  photographs, profiles, audio, video, items, and links that you
                  import, submit, post, and/or display, or cause to be imported,
                  submitted, posted and/or displayed, on the Site are your own
                  or, if owned by another party, you are using them on the Site
                  as expressly agreed with the owner.
                </p>
                <p>
                  You will not via any channel send unwanted, unsolicited
                  communications – or spam – to other users of this Site.
                </p>
                <p>
                  You will not transmit or seek to transmit any electronic
                  viruses or worms or any electronic code that seeks to alter
                  this Site or that is in any way destructive.
                </p>
                <p>
                  You will not collect information about users, including email
                  addresses, of this Site.
                </p>
                <p>
                  Tremo seeks to promote legal activity and ‘good
                  behaviour’ on this Site. However, you understand and agree
                  that Tremo is not responsible for the content posted on
                  the Site and that you may be exposed to content and behaviour
                  that contravenes these Terms. Accordingly, you agree to use
                  the Site at your own risk.
                </p>
                <p>
                  You may link to the Site, provided you do so in a way that is
                  fair and legal and does not damage our reputation or take
                  advantage of it, but you must not establish a link in such a
                  way as to suggest any form of association, approval or
                  endorsement on our part where none exists. We expressly
                  reserve the right to revoke our permission to link to the Site
                  and to take any action we deem appropriate. The Site must not
                  be framed on any other website.
                </p>

                <br />
                <h4>Intellectual Property</h4>
                <p>
                  You agree to be solely responsible for your and your
                  representatives’ conduct and activities on the Site and any
                  and all of the data, text, information, usernames, graphics,
                  images, photographs, profiles, audio, video, items, and links
                  (together, “Content”) that you email, submit, post, and
                  display on or to the Site. You will not: (i) email to or post
                  on the Site any false, inaccurate, misleading, obscene,
                  defamatory or libellous Content; (ii) falsely state or
                  otherwise misrepresent your affiliation with any person or
                  entity, through for example, the use of similar email address,
                  nicknames, or creation of false account(s); (iii) infringe
                  upon or misappropriate our or any third party’s copyright,
                  patent, trademark, trade secret or other proprietary or
                  intellectual property rights or rights of publicity or
                  privacy; (iv) transmit any code that may damage, detrimentally
                  interfere with, surreptitiously intercept or expropriate any
                  system, data or personal information; (vi) modify, adapt or
                  hack the Site or falsely imply that any other website is
                  associated with Tremo; or (vi) create any liability for
                  us to any third party. You agree to indemnify us against all
                  legal fees, damages and other expenses that may be incurred by
                  us as a result of your breach of any of the above warranties.
                  Tremo is not legally responsible for any remarks,
                  information or other content posted or made available on the
                  Site by any Site user or third party. We are not responsible
                  for and do not monitor or censor content for accuracy.
                  However, we reserve the right to remove or restrict access to
                  any information or content posted or made available on the
                  Site in our sole discretion or if ordered to do so by a court.
                </p>
                <p>
                  Tremo may change or remove content, or accounts
                  containing content, that Tremo determines to be unlawful,
                  unfair, offensive, threatening, libellous, defamatory, obscene
                  or otherwise objectionable or violates any party’s
                  intellectual property, the Terms and Conditions or other
                  documents and guidelines as posted on the Site.
                </p>

                <br />
                <h4>Content</h4>
                <p>You agree to be solely responsible for your and your representatives’ conduct and activities on the Site and any and all of the data, text, information, usernames, graphics, images, photographs, profiles, audio, video, items, and links (together, “Content”) that you submit, post, and display on the Site. You will not: (i) post false, inaccurate, misleading, obscene, defamatory or libellous Content; (ii) falsely state or otherwise misrepresent your affiliation with any person or entity, through for example, the use of similar email address, nicknames, or creation of false account(s); (iii) infringe upon or misappropriate our or any third party’s copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of publicity or privacy; (iv) transmit any code that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information; (vi) modify, adapt or hack the Site or falsely imply that any other website is associated with Tremo; or (vi) create any liability for us to any third party. You agree to indemnify us against all legal fees, damages and other expenses that may be incurred by us as a result of your breach of any of the above warranties.</p>
                <p>Tremo is not legally responsible for any remarks, information or other content posted or made available on the Site by any Site user or third party. We are not responsible for and do not monitor or censor content for accuracy. However, we reserve the right to remove or restrict access to any information or content posted or made available on the Site in our sole discretion or if ordered to do so by a court.</p>
                <p>Tremo may change or remove content, or accounts containing content, that Tremo determines to be unlawful, unfair, offensive, threatening, libellous, defamatory, obscene or otherwise objectionable or violates any party’s intellectual property, the Terms and Conditions or other documents and guidelines as posted on the Site.</p>

                <br />
                <h4>License to Tremo</h4>
                <p>
                  By importing, submitting, posting, and/or displaying, or causing to be imported, submitted, posted and/or displayed, any Content on or to the Site, you hereby grant Tremo a perpetual, worldwide, royalty-free, non-exclusive, sub-licensable (through multiple tiers) right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, make available to the public, and exercise all copyright, trademark, publicity, and database rights with respect to such Content and/or to incorporate such Content in other works in any media now known or later developed for the full term of any rights that may exist in such Content. You agree to allow us to store or re-format such Content on the Site and display such Content in any way we choose in our sole discretion. You agree to allow us to use such Content in our advertising and marketing, and to display such Content on other websites in connection with our activities on the other websites.
                </p>

                <br />
                <h4>INSTAGRAM CONTENT</h4>
                <p>
                  If you provide us with your Instagram username to allow us to import your Content from your Instagram account, you confirm that such Instagram username is yours, you authorise us to access all of your Content from your Instagram account, you permit us to import your Content from your Instagram account to your Tremo account and, for the avoidance of doubt, the sections above entitled “Content” and “License to Tremo” shall apply to all such Content imported from Instagram.
                </p>

                <br />
                <h4>WARRANTY DISCLAIMER</h4>
                <p>
                  TREMO MAKES NO EXPRESS REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE SITE, WORK PRODUCTS, TREMO RESOURCES, OR ANY ACTIVITIES OR ITEMS RELATED TO THESE TERMS, ALL OF WHICH ARE PROVIDED “AS IS.” TO THE MAXIMUM EXTENT PERMITTED BY LAW, TREMO DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES. IN ADDITION, YOU AGREE THAT NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM TREMO SHALL CREATE ANY WARRANTY OF ANY KIND NOT EXPRESSLY MADE HEREIN.
                </p>

                <br />
                <h4>LIMITATION OF LIABILITY</h4>
                <p>
                  IN NO EVENT SHALL TREMO, AND (AS APPLICABLE) TREMO’S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES OR TREMO’S CONSULTANTS OR SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING TO (1) THE CONDUCT OF ANY SITE USER IN CONNECTION WITH THE USE OF THE SITE, (2) LOSS OF DATA, (3) LOSS OF REVENUE OR ANTICIPATED PROFITS, (4) LOSS OF BUSINESS, (5) LOSS OF OPPORTUNITY, (6) LOSS OF GOODWILL OR INJURY TO REPUTATION, (7) LOSSES SUFFERED BY THIRD PARTIES, OR (8) ANY INDIRECT, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING FROM THE USE OF THE SITE REGARDLESS OF THE FORM OF ACTION. TREMO’S LIABILITY, AND (AS APPLICABLE) THE LIABILITY OF TREMO’S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, SUPPLIERS AND CONSULTANTS, TO ANY USER OR THIRD PARTIES IN ANY OTHER CIRCUMSTANCE IS LIMITED TO £100. THESE LIMITATIONS SHALL APPLY TO ANY LIABILITY, ARISING FROM ANY CAUSE OF ACTION WHATSOEVER, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH COSTS OR DAMAGES AND EVEN IF THE LIMITED REMEDIES PROVIDED HEREIN FAIL OF THEIR ESSENTIAL PURPOSE.
                </p>

                <br />
                <h4>Indemnity</h4>
                <p>
                  You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, officers, directors, consultants, suppliers, agents and employees harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of the Terms and Conditions or your violation of any law or the rights of a third party.
                </p>

                <br />
                <h4>No Guarantee</h4>
                <p>
                  We do not guarantee continuous, uninterrupted access to the Site or any of the Site’s features, and operation of the Site may be interfered with by numerous factors outside our control.
                </p>

                <br />
                <h4>Legal Compliance</h4>
                <p>
                  You agree to comply with all applicable UK and other international laws, statutes, ordinances and regulations regarding your use of the Site.
                </p>

                <br />
                <h4>No Agency</h4>
                <p>
                  You and Tremo are independent entities, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by these Terms.
                </p>

                <br />
                <h4>Choice of Law</h4>
                <p>
                  These Terms shall in all respects be governed by and interpreted in accordance with the laws of England and Wales.
                </p>

                <br />
                <h4>Electronic Communications</h4>
                <p>
                  When you visit the Site or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on this Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                </p>

                <br />
                <h4>General</h4>
                <p>
                  If any provision of these Terms is held to be unenforceable, such provision shall be struck and the remaining provisions remain enforceable. Tremo may assign these Terms by providing notice to you. You may not assign these Terms, nor any of its rights or obligations, without Tremo’s prior written consent. The failure or delay of either party to exercise or enforce any right or claim does not constitute a waiver of such right or claim and shall in no way affect that party’s right to later enforce or exercise it, unless such party issues an express written waiver, signed by a duly authorized representative of each party.
                </p>

                <br />
                <h4>Modification</h4>
                <p>
                  We reserve the right to modify these Terms at any time by posting a revised and updated Terms of Use that is always accessible through this Site’s home page. Your continued use of this Site after a modification indicates your acceptance of the amended Terms of Use. You should regularly check these Terms for modification.
                </p>

                <br />
                <h4>Notices</h4>
                <p>
                  We may give notice to you by means of (i) a general notice in your account information; (ii) by email to your e-mail address on record with us, or (iii) written communication sent to you by first class mail to your address on record with us. Such notice shall be deemed to have been given upon the expiration of forty-eight (48) hours after mailing or posting (if sent by first class mail) or twenty-four (24) hours after sending (if by e-mail).
                </p>

                <br />
                <h4>Entire Agreement</h4>
                <p>
                  These Terms (which requires your agreement to the Terms and Conditions) sets forth the entire understanding and agreement between the parties with respect to the subject matter hereof and supersedes all other communications regarding the subject matter hereof.
                </p>

                <br />
                <h4>Survival</h4>
                <p>
                  The Sections with the following titles survive any termination or expiration of these Terms: Intellectual Property, Content, License to Tremo, Warranty Disclaimer, Limitation of Liability, Indemnity and Choice of Law.
                </p>

                <br />
                <h4>Contact Us</h4>
                <p>If you have any questions, please contact us at <a href="mailto:contact@tremobooks.com">contact@tremobooks.com</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
