import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import asyncComponent from './routing/AsyncComponent'
import Route from './routing/Route'
import AuthenticatedRoute from './routing/AuthenticatedRoute'
import analytics from './services/analytics'

// V2
import { LandingPage } from './modules/landing/LandingPage'
import { Signup, SignupJournal, SignupAddCard, SignupCompleted } from './modules/signup'
import { QuestionEntry } from './modules/journal'
import { PrivacyPolicy, TermsPolicy, ShippingPolicy, RefundsPolicy } from './modules/common'
// V2

const trackPage = (path, search = '') => {
  let pageRoute = `${path}${search}`

  let page = pageRoute
  if (pageRoute.includes('/books/active')) {
    page = '/book/active'
  }

  if (pageRoute.includes('book') && pageRoute.includes('page')) {
    page = '/book/page'
  }

  if (pageRoute.includes('book') && pageRoute.includes('delete')) {
    page = '/book/delete'
  }

  if (pageRoute.includes('book') && pageRoute.includes('reorder')) {
    page = '/book/reorder'
  }

  if (pageRoute.includes('book') && pageRoute.includes('import')) {
    page = '/book/import'
  }

  if (pageRoute === '/register') {
    return analytics.page('Register')
  }

  // console.log('Tracking page', page)
  // googleService.trackPage(googlePage)
  // facebookService.trackPage(googlePage)
  analytics.page(page)
}

const AsyncBookCreationLoading = asyncComponent(() => import('./components/BookCreationLoading'))
const AsyncOnboardingImportPhotos = asyncComponent(() => import('./containers/OnboardingImportPhotos'))
const AsyncKeepCaptions = asyncComponent(() => import('./components/KeepCaptions'))
const AsyncChooseCovers = asyncComponent(() => import('./components/ChooseCovers'))
const AsyncSelectPhotos = asyncComponent(() => import('./containers/SelectPhotos'))
const AsyncBookView = asyncComponent(() => import('./components/BookView'))

const AsyncOnboardingUploadPhotos = asyncComponent(() => import('./containers/OnboardingUploadPhotos'))

const AsyncLogin = asyncComponent(() => import('./containers/Login'))
const AsyncLogout = asyncComponent(() => import('./containers/Logout'))
const AsyncNotFound = asyncComponent(() => import('./containers/NotFound'))

const AsyncResetPasswordRequestView = asyncComponent(() => import('./containers/ResetPasswordRequestView'))
const AsyncResetPasswordView = asyncComponent(() => import('./containers/ResetPasswordView'))

const AsyncLoginWithToken = asyncComponent(() => import('./containers/LoginWithToken'))

const AsyncAlbumsView = asyncComponent(() => import('./containers/AlbumsView'))
const AsyncUnsubscribeView = asyncComponent(() => import('./containers/UnsubscribeView'))
const AsyncBookPagesReorderView = asyncComponent(() => import('./containers/BookPagesReorderView'))

const AsyncSwipeableBookView = asyncComponent(() => import('./containers/SwipeableBookView'))
const AsyncAccountView = asyncComponent(() => import('./containers/AccountView'))

const AsyncChangePassword = asyncComponent(() => import('./containers/ChangePassword'))
const AsyncChangeIGAccount = asyncComponent(() => import('./containers/ChangeIGAccount'))
const AsyncAddIGAccount = asyncComponent(() => import('./containers/AddIGAccount'))
const AsyncBulkDeleteView = asyncComponent(() => import('./containers/BulkDeleteView'))

const AsyncImportMoreIGView = asyncComponent(() => import('./containers/ImportMoreIGView'))

/* Register routes */
const AsyncRegisterAddIGView = asyncComponent(() => import('./containers/RegisterAddIGView'))
const AsyncRegisterAddUserDetailsView = asyncComponent(() => import('./containers/RegisterAddUserDetailsView'))
const AsyncRegisterPrivateInstagramView = asyncComponent(() => import('./containers/RegisterPrivateInstagramView'))
const AsyncRegisterInvalidInstagramView = asyncComponent(() => import('./containers/RegisterInvalidInstagramView'))
/* Register routes */

/* Books routes */
const AsyncBooksOrderedView = asyncComponent(() => import('./containers/BooksOrderedView'))

const AsyncBulkSelectView = asyncComponent(() => import('./containers/BulkSelectView'))
const AsyncBooksPageView = asyncComponent(() => import('./containers/BooksPageView'))

const AsyncPagesBulkDeleteView = asyncComponent(() => import('./containers/PagesBulkDeleteView'))
/* Books routes */

/* Unsubscribe from emails view */
const AsyncEmailUnsubscribeView = asyncComponent(() => import('./containers/EmailUnsubscribeView'))

/* New Onboarding */
const AsyncOnboardingAddIGView = asyncComponent(() => import('./containers/OnboardingAddIGView'))
/* New Onboarding */

/** Others */
const AsyncUpdateInstagramView = asyncComponent(() => import('./containers/UpdateInstagramView'))

/** New Order */
const AsyncOrderBookDetailsView = asyncComponent(() => import('./containers/OrderBookDetailsView'))
const AsyncOrderDeliveryDetailsView = asyncComponent(() => import('./containers/OrderDeliveryDetailsView'))
const AsyncOrderPayView = asyncComponent(() => import('./containers/OrderPayView'))
const AsyncOrderSubscriptionView = asyncComponent(() => import('./containers/OrderSubscriptionView'))
const AsyncOrderSubscriptionVoucher15View = asyncComponent(() => import('./containers/OrderSubscriptionVoucher15View'))
const AsyncOrderSubscriptionVoucher25View = asyncComponent(() => import('./containers/OrderSubscriptionVoucher25View'))
const AsyncOrderCompleted = asyncComponent(() => import('./containers/OrderCompleted'))

/** Gift Voucher & Email Bot landing page */
const AsyncGiftCardView = asyncComponent(() => import('./containers/GiftCardView'))

const AsyncTest = asyncComponent(() => import('./containers/Test'))

// NEW ROUTES
// Onboarding
const AsyncAddPhotosSelect = asyncComponent(() => import('./containers/onboarding/AddPhotosSelect'))

// Auth
const AsyncInstagramAuth = asyncComponent(() => import('./containers/auth/InstagramAuth'))

// Checkout
const AsyncCheckoutView = asyncComponent(() => import('./containers/checkout/CheckoutView'))

export default ({ childProps }) => (
  <Switch>
    {/* New Routes - Start */}
    <Route path="/" exact component={LandingPage} props={childProps} trackPage={trackPage} />
    <Route path="/signup" exact component={Signup} props={childProps} trackPage={trackPage} />
    <Route path="/signup/journal" exact component={SignupJournal} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/signup/card" exact component={SignupAddCard} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/signup/completed" exact component={SignupCompleted} props={childProps} trackPage={trackPage} />
    <Route path="/q/:uuid" exact component={QuestionEntry} props={childProps} trackPage={trackPage} />

    <Route path="/privacy-policy" exact component={PrivacyPolicy} props={childProps} trackPage={trackPage} />
    <Route path="/terms-policy" exact component={TermsPolicy} props={childProps} trackPage={trackPage} />
    <Route path="/shipping-policy" exact component={ShippingPolicy} props={childProps} trackPage={trackPage} />
    <Route path="/refunds-policy" exact component={RefundsPolicy} props={childProps} trackPage={trackPage} />
    {/* New Routes - End*/}

    <Route path="/login" exact component={AsyncLogin} props={childProps} trackPage={trackPage} />
    <Route path="/auth/instagram" exact component={AsyncInstagramAuth} props={childProps} trackPage={trackPage} />
    

    {/* <Redirect from="/" exact to={{ pathname: '/books/active' }} /> */}

    <Route path="/login/token" exact component={AsyncLoginWithToken} props={childProps} trackPage={trackPage} />

    <Route path="/resetPasswordRequest" exact component={AsyncResetPasswordRequestView} props={childProps} trackPage={trackPage} />
    <Route path="/resetPassword" exact component={AsyncResetPasswordView} props={childProps} trackPage={trackPage} />

    <Route path="/email-unsubscribe" exact component={AsyncEmailUnsubscribeView} props={childProps} trackPage={trackPage} />

    {/* New Routes */}
    <Route path="/onboarding/add-photos-select" exact component={AsyncAddPhotosSelect} props={childProps} trackPage={() => analytics.page('Start')} />
    {/* New Routes */}
    
    {/* Register pages */}
    <Route path="/register" exact component={AsyncRegisterAddUserDetailsView} props={childProps} trackPage={() => analytics.page('Sign Up')} />
    <Route path="/register/add-instagram-username" exact component={AsyncRegisterAddIGView} props={childProps} trackPage={trackPage} />
    <Route path="/register/instagram-username/private" exact component={AsyncRegisterPrivateInstagramView} props={childProps} trackPage={() => analytics.page('Private Instagram')} />
    <Route path="/register/instagram-username/invalid" exact component={AsyncRegisterInvalidInstagramView} props={childProps} trackPage={() => analytics.page('Invalid Instagram')} />
    {/*<Route path="/register/:language" exact component={AsyncOnboardingImportPhotos} props={childProps} trackPage={() => analytics.page('Start')} />*/}
    <Route path="/create-my-book" exact component={AsyncOnboardingImportPhotos} props={childProps} trackPage={() => analytics.page('Start')} />

    <Route path="/create-my-book/ig" exact component={AsyncOnboardingImportPhotos} props={childProps} trackPage={() => analytics.page('Start')} />
    <Route path="/onboarding/import-photos" exact component={AsyncOnboardingImportPhotos} props={childProps} trackPage={() => analytics.page('Start')} />

    <Route path="/gift-card" exact component={AsyncGiftCardView} props={childProps} trackPage={() => analytics.page('Gift Voucher')} />

    {/* Register pages */}

    {/* Onboarding */}
    <Route path="/onboarding/add-instagram" exact component={AsyncOnboardingAddIGView} props={childProps} trackPage={trackPage} />
    <Route path="/onboarding/keep-captions" exact component={AsyncKeepCaptions} props={childProps} trackPage={() => analytics.page('Keep Captions')} />

    <Route path="/onboarding/upload-photos" exact component={AsyncOnboardingUploadPhotos} props={childProps} trackPage={trackPage} />


    <AuthenticatedRoute path="/onboarding/select-instagram-photos" exact component={AsyncSelectPhotos} props={childProps} trackPage={() => analytics.page('Select Instagram Photos')} />
    <AuthenticatedRoute path="/onboarding/choose-cover/:side" exact component={AsyncChooseCovers} props={childProps} trackPage={(path, search = '') => path.includes('front') ? analytics.page('Select Front Cover') : analytics.page('Select Back Cover')} />
    {/* Onboarding */}

    {/* Order Pages*/}
    {/*<AuthenticatedRoute path="/order" exact component={AsyncOrderBookDetailsView} props={childProps} trackPage={trackPage} />*/}
    <AuthenticatedRoute path="/order" exact component={AsyncCheckoutView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/book/:bookId/order" exact component={AsyncOrderBookDetailsView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/choose-cover/:side" component={AsyncChooseCovers} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/delivery-details" exact component={AsyncOrderDeliveryDetailsView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/change-address" exact component={AsyncOrderDeliveryDetailsView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/pay" exact component={AsyncOrderPayView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/subscription" exact component={AsyncOrderSubscriptionView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/subscription/voucher15" exact component={AsyncOrderSubscriptionVoucher15View} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/subscription/voucher25" exact component={AsyncOrderSubscriptionVoucher25View} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/order/completed" exact component={AsyncOrderCompleted} props={childProps} trackPage={trackPage} />


    {/* Books pages */}
    <AuthenticatedRoute path="/books" exact component={AsyncBooksOrderedView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/books/:bookId" exact component={AsyncBookView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/books/:bookId/pages/delete" exact component={AsyncPagesBulkDeleteView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/books/:bookId/pages/:pageId" exact component={AsyncBooksPageView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/books/:bookId/select-photos" exact component={AsyncSelectPhotos} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/change/account/select-photos" exact component={AsyncSelectPhotos} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/books/:bookId/choose-cover/:side" component={AsyncChooseCovers} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/book/:bookId/keep-captions" exact component={AsyncKeepCaptions} props={childProps} trackPage={trackPage} />
    {/* Books pages */}

    {/** Others */}
    <AuthenticatedRoute path="/instagram/update" exact component={AsyncUpdateInstagramView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/instagram/change-account" exact component={AsyncChangeIGAccount} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/instagram/add-account" exact component={AsyncAddIGAccount} props={childProps} trackPage={trackPage} />

    <AuthenticatedRoute path="/account" exact component={AsyncAccountView} props={childProps} trackPage={trackPage} />

    <AuthenticatedRoute path="/unsubscribe" exact component={AsyncUnsubscribeView} props={childProps} trackPage={trackPage} />

    <AuthenticatedRoute path="/changePassword" exact component={AsyncChangePassword} props={childProps} trackPage={trackPage} />

    <AuthenticatedRoute path="/book/:bookId/page/:pageId" exact component={AsyncSwipeableBookView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/book/:bookId/delete" exact component={AsyncBulkDeleteView} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/book/:bookId/reorder" exact component={AsyncBookPagesReorderView} props={childProps} trackPage={trackPage} />

    <AuthenticatedRoute path="/book/:bookId/ig/import/more" exact component={AsyncImportMoreIGView} props={childProps} trackPage={trackPage} />

    <AuthenticatedRoute path="/login" exact component={AsyncLogin} props={childProps} trackPage={trackPage} />
    <AuthenticatedRoute path="/logout" exact component={AsyncLogout} props={childProps} trackPage={trackPage} />



    { /* Finally, catch all unmatched routes */ }
    <Route component={AsyncNotFound} trackPage={trackPage} />
  </Switch>
)
