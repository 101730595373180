import React from 'react'
import { Link } from 'react-router-dom'
import { HOME_LOCATION } from '../../common/constants'
import LOGO from "../../images/logo-transparent.png"

export const LogoCenter = () => {
  return (
    <div className="col-12 text-center mt-7">
      <Link to={HOME_LOCATION}>
        <img src={LOGO} style={{ height: '38px', cursor: 'pointer' }} />
      </Link>
    </div>
  )
}