import axios from 'axios'
import urljoin from 'url-join'

import { API_URL, API_TOKEN } from '../constants'
import authService from './auth'

class API {
  async makeRequest({ method = 'get', url, data = {}, token = null }) {
    let authHeader = null

    if (!token && authService.isAuthenticated()) {
      authHeader = authService.getAuthToken()
    }

    if (token) {
      authHeader = token
    }

    const res = await axios({
      method: method,
      url: urljoin(API_URL, url),
      [method.toLowerCase() === 'get' ? 'params' : 'data']: {
        ...data,
      },
      headers: {
        'X-API-CLIENT': API_TOKEN,
        'X-API-USER': authHeader,
      },
    }).catch((err) => {
      console.log('api error?')
      // if it's an API error
      if (err.response && err.response.data) {
        return err.response
      }

      throw err
    })

    if (!res.data) {
      throw new Error('Invalid response. Please refresh the page.')
    }
    // console.log("ERROR", res.data.error, res.data.error === 'AuthError')
    if (res.data.error === 'AuthError') {
      // res.status === 401 &&
      console.log('auth error')
      authService.logout()

      throw new Error(`${res.data.message}`)
    }

    if (!res.data.success || res.data.success === 'false') {
      // @todo
      throw new Error(res.data.message ? res.data.message : 'Unknown error')
    }

    return res.data.data
  }
}

export default new API()

window._api = new API()
