import api from './api'
import authService from './auth'
import consistency from './consistency'

import googleService from '../services/google'
import analytics from '../services/analytics'

class OrdersService {
  async pay(stripeData, orderDetails) {
    const userId = authService.getUserId()
    const res = await api.makeRequest({
      method: 'post',
      url: `/users/${userId}/payments`,
      data: {
        paymentDetails: stripeData,
        orderDetails: orderDetails,
      },
    })

    consistency.clearAlbumPages(0)
    return res
  }

  async payWithStripe(stripeData, orderDetails) {
    const userId = authService.getUserId()
    const res = await api.makeRequest({
      method: 'post',
      url: `/users/${userId}/payments/stripe`,
      data: {
        stripePaymentDetails: stripeData,
        orderDetails: orderDetails,
      },
    })

    OrdersService._trackPurchase(res.order.id, orderDetails)
    return res
  }

  async subscribeWithStripe(stripeData) {
    const userId = authService.getUserId()
    const res = await api.makeRequest({
      method: 'post',
      url: `/users/${userId}/payments/stripe/subscription`,
      data: {
        stripeData,
      },
    })

    // OrdersService._trackPurchase(res.order.id, orderDetails)
    await authService.updateUser()
    return res
  }

  async payWithPaypal(paypalData, orderDetails) {
    const userId = authService.getUserId()
    const res = await api.makeRequest({
      method: 'post',
      url: `/users/${userId}/payments/paypal`,
      data: {
        paypalPaymentDetails: paypalData,
        orderDetails: orderDetails,
      },
    })

    OrdersService._trackPurchase(res.order.id, orderDetails)
    consistency.clearAlbumPages(0)
    return res
  }

  async applyCode(code, price) {
    const res = await api.makeRequest({
      method: 'POST',
      url: `/users/${authService.getUserId()}/applycode`,
      data: {
        code,
        price,
      },
    })
    return res
  }

  async getOrderDetails() {
    const res = await api.makeRequest({
      method: 'GET',
      url: `/users/${authService.getUserId()}/orderDetails`,
    })

    return res.details
  }

  async setOrderDetails(details) {
    const res = await api.makeRequest({
      method: 'PUT',
      url: `/users/${authService.getUserId()}/orderDetails`,
      data: {
        ...details,
      },
    })
    return res.details
  }

  async getOrder(albumId) {
    const res = await api.makeRequest({
      method: 'GET',
      url: `/users/${authService.getUserId()}/albums/${albumId}/order`,
    })
    return res.details
  }

  async updateAddress(details) {
    const res = await api.makeRequest({
      method: 'PUT',
      url: `/users/${authService.getUserId()}/order-address`,
      data: {
        ...details,
      },
    })
    return res
  }

  static _trackPurchase(orderId, orderDetails) {
    analytics.orderCompleted({
      userId: authService.getUserId(),
      orderId: orderId,
      productId: 'photobook_wrap_21x21_square',
      countPages: orderDetails.countPages,
      quantity: orderDetails.quantity,
      amount: orderDetails.amount,
      currency: orderDetails.currency,
    })

    /*if(!orderId || !orderDetails || orderDetails.amount < 1500) {
      return
    }*/

    /*googleService.purchase({
      'transaction_id': orderId,
      // 'affilation': 'tremobooks.com',
      'value': orderDetails.amount / 100,
      'currency': orderDetails.currency,
      'coupon': orderDetails.promoCode,
    })*/
  }
}

export default new OrdersService()

window.ordersService = new OrdersService()
