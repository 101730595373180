import ls from 'local-storage'
import api from './api'
import consistencyService from './consistency'
import usersService from './users'


const USER_STORAGE = 'UserData';

class AuthService {
  userDataWatchers = []

  async login(email, password) {
    let res;

    try {
      res = await api.makeRequest({
        method: 'post',
        url: '/users/auth',
        data: {
          email,
          password,
        },
      })
    } catch (err) {
      this.clearUserData()
      throw err
    }

    this.setUserData(res)
    return res.user
  }

  async loginWithToken(token) {
    this.clearUserData()

    let res
    try {
      res = await api.makeRequest({
        method: 'post',
        url: `/users/auth/token`,
        data: {
          token
        },
      })
    } catch (err) {
      this.clearUserData()
      throw err
    }

    this.setUserData(res)
    return res
  }

  async logoutToken() {
    try {
      await api.makeRequest({
        method: 'post',
        url: '/users/self/logout',
      })
    } catch (err) {
      console.error('failed to logout>', err)
      // don't re-throw, fuck it
    }

    consistencyService.clearAllAlbumsPages()
  }

  async logout() {
    // await this.logoutToken()
    this.clearUserData()
    ls.clear()
    consistencyService.clearAllAlbumsPages()
  }

  isAuthenticated() {
    const userData = this.getUserData()
    if (!userData || !userData.session || !userData.session.token) {
      return false
    }

    return true
  }

  getAuthToken() {
    const userData = this.getUserData();
    return userData.session.token;
  }

  getUserData() {
    const data = ls.get(USER_STORAGE);

    if (data && typeof data === 'object' && data.session && data.session === 'object' && (!data.session.token)) {
      // corrupted data
      this.clearUserData();
      return null;
    }

    if (!data) {
      return null;
    }

    return data;
  }

  getUserId() {
    const authUser = this.getUserData()
    if (authUser !== null) {
      return authUser.user.id
    }

    return null
  }

  setUserData(data) {
    ls.set(USER_STORAGE, data)
    this._notifyUserDataWatchers(data)
  }

  async updateUser() {
    const { user } = await usersService.get()
    this.updateUserData(user)
  }

  updateUserData(newUser) {
    this.setUserData({
      ...this.getUserData(),
      user: newUser,
    })
  }

  updateChildrenData(children) {
    const data = this.getUserData()
    this.setUserData({
      ...data,
      user: {
        ...data.user,
        children
      },
    })
  }

  clearUserData() {
    //ls.remove(USER_STORAGE);
    ls.clear()
    this._notifyUserDataWatchers(null);
  }

  watchUserData(callback) {
    if (!callback) {
      throw new Error('watchUserData: no callback given');
    }

    // add the watcher to local watching
    this.userDataWatchers = [
      ...this.userDataWatchers.filter(watcher => watcher !== callback),
      callback,
    ];

    // watch for storage events from other tabs
    ls.on(USER_STORAGE, callback);
  }

  unwatchUserData(callback) {
    if (!callback) {
      throw new Error('unwatchUserData: no callback given');
    }

    this.userDataWatchers = [
      ...this.userDataWatchers.filter(watcher => watcher !== callback),
    ]

    ls.off(USER_STORAGE, callback);
  }

  _notifyUserDataWatchers(data) {
    this.userDataWatchers.map(watcher => watcher(data));
  }
}

export default new AuthService();
