import api from './api'

class FilesService {
  async getSignedUrl(fileName, fileType) {
    const res = await api.makeRequest({
      method: 'get',
      url: `/files/upload/sign?file_name=${fileName}&file_type=${fileType}`,
    })

    console.log(res)

    return {
      pathName: res.pathName,
      signedRequestUrl: res.url,
      publicUrl: res.public_url,
    }
  }
}

export default new FilesService()
