import api from '../../services/api'
// import authService from '../../services/auth'

export default {
  getQuestionByUuid: async (uuid: string) => {
    const {
      userQuestion: { question, user },
    } = await api.makeRequest({
      method: 'GET',
      url: `/v2/users/questions/uuid/${uuid}`,
    })

    return {
      question: question.question,
      childName: user.childName,
    }
  },
  addPageByQuestion: async ({
    questionUuid,
    caption,
    imagePath,
    imagePublicUrl,
    imageType,
    imageCreatedAtOriginal,
  }: {
    questionUuid: string
    caption: string
    imagePath: string
    imagePublicUrl: string
    imageType: string
    imageCreatedAtOriginal: string
  }) => {
    await api.makeRequest({
      method: 'POST',
      url: `/v2/users/questions/uuid/${questionUuid}/pages`,
      data: {
        caption,
        imagePath,
        imagePublicUrl,
        imageType,
        imageCreatedAtOriginal,
      },
    })
  },
}
