import bugsnagClient from './services/bugsnag'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { initializeIcons } from '@uifabric/icons'

import App from './App'

initializeIcons()

var ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <App />
    </Router>
  </ErrorBoundary>
  , document.getElementById('root')
)
