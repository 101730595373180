// config based on env
export const env = process.env.REACT_APP_ENV || 'local'
console.log(process.env.REACT_APP_ENV)

const config = {
  production: {
    STRIPE_KEY: 'pk_live_PTSW27XoCDrrZwaYfaqqht2e',
    BUGSNAG_KEY: '05bacf05977e77fb55a780a9b356da66',
    API_URL: 'https://api-production.tremobooks.com',
    // API_URL: 'http://api-server.tremobooks.com',
    // API_URL: 'http://api.tremo.ngrok.io',
    API_TOKEN: 'mHQRJfXgE5GN15ecFkQSu2DzbstntIl5',
    INTERCOM_KEY: 'pjyrnlkh',
    GOOGLE_TRACKING_ID: 'UA-114797412-1',
    GEO_PLUGIN_KEY: 'fbdce58ae4c049f6',
    PAYPAL_KEY:
      'AWacKd1rp2Tlf6Tuera2PWLODgiq4mk1sCiLC5-pIMTmeo1C3UxFdzShU53EjU5Fhi7ndBo50IS3qa1w',
    SEGMENT_KEY: 'yU09CgR0lm4rzhRHGVs5SmlG1yYBLJwM',
    INSTAGRAM_APP_ID: '2504543033162763',
    INSTAGRAM_REDIRECT_URI: 'https://tremobooks.com/auth/instagram',
  },
  staging: {
    STRIPE_KEY: '@todo',
    BUGSNAG_KEY: '05bacf05977e77fb55a780a9b356da66',
    API_URL: '@todo',
    API_TOKEN: '@todo',
  },
  development: {
    STRIPE_KEY: 'pk_test_T8AMyvLAx4DBhIxWxiU05Pdj',
    BUGSNAG_KEY: '05bacf05977e77fb55a780a9b356da66',
    API_URL: 'https://api-development.tremobooks.com',
    API_TOKEN: 'mHQRJfXgE5GN15ecFkQSu2DzbstntIl5',
    INTERCOM_KEY: 'y7jemmpv',
    GOOGLE_TRACKING_ID: '',
    GEO_PLUGIN_KEY: 'fbdce58ae4c049f6',
    PAYPAL_KEY:
      'AU4wkkbHZ2ixhUxCX5s3TtChVGHMkj3enXOpEYFkvZmseVCwUiRUPo1clLoXZhh1o7GF6K1vdkdUCDK6',
    SEGMENT_KEY: 'zEzNhHffJswvy5N16KtNZhRcVkh18nYi',
    INSTAGRAM_APP_ID: '2425057887760819',
    INSTAGRAM_REDIRECT_URI: 'https://development.tremobooks.com/auth/instagram',
  },
  local: {
    STRIPE_KEY: 'pk_test_T8AMyvLAx4DBhIxWxiU05Pdj',
    BUGSNAG_KEY: '05bacf05977e77fb55a780a9b356da66',
    // API_URL: 'https://w9j8op6cql.execute-api.eu-west-2.amazonaws.com/development',
    // API_URL: 'http://localhost:3000/development',
    // API_URL: 'http://localhost:3000/development',
    // API_URL: 'api.tremo.ngrok.io',
    API_URL: 'http://localhost:3000',
    API_TOKEN: 'mHQRJfXgE5GN15ecFkQSu2DzbstntIl5',
    INTERCOM_KEY: 'y7jemmpv',
    GOOGLE_TRACKING_ID: '',
    GEO_PLUGIN_KEY: 'fbdce58ae4c049f6',
    PAYPAL_KEY:
      'AU4wkkbHZ2ixhUxCX5s3TtChVGHMkj3enXOpEYFkvZmseVCwUiRUPo1clLoXZhh1o7GF6K1vdkdUCDK6',
    SEGMENT_KEY: 'zEzNhHffJswvy5N16KtNZhRcVkh18nYi',
    INSTAGRAM_APP_ID: '2425057887760819',
    INSTAGRAM_REDIRECT_URI: 'https://web.tremo.ngrok.io/auth/instagram',
  },
}

export const STRIPE_KEY = config[env].STRIPE_KEY
export const BUGSNAG_KEY = config[env].BUGSNAG_KEY
export const API_URL = config[env].API_URL
export const API_TOKEN = config[env].API_TOKEN
export const INTERCOM_KEY = config[env].INTERCOM_KEY
export const GOOGLE_TRACKING_ID = config[env].GOOGLE_TRACKING_ID
export const GEO_PLUGIN_KEY = config[env].GEO_PLUGIN_KEY
export const PAYPAL_SANDBOX_KEY = config.development.PAYPAL_KEY
export const PAYPAL_PRODUCTION_KEY = config.production.PAYPAL_KEY
export const SEGMENT_KEY = config[env].SEGMENT_KEY
export const INSTAGRAM_APP_ID = config[env].INSTAGRAM_APP_ID
export const INSTAGRAM_REDIRECT_URI = config[env].INSTAGRAM_REDIRECT_URI
