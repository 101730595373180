import api from './api'
import authService from './auth'
import ordersService from './orders'
import helpersService from './helpers'
import geoLocationService from './geoLocation'
import uuidv4 from 'uuid/v4'

export enum UserType {
  photobook = 'photobook',
  journal = 'journal',
}

class UserService {
  /**
   * Start V2 API
   */
  async register(input) {
    // if user already logged in - throw Error
    const user = authService.getUserData()
    if (user) {
      throw new Error(`User already logged in.`)
    }

    let countryCode = null,
      region = null
    try {
      const countryData = await geoLocationService.getCountryData()
      const data = this.getCountryAndRegion(countryData)

      countryCode = countryData.alpha3Code
      region = data.region
    } catch (error) {
      console.log('ERROR: detect country', error)
    }

    const userData = await api.makeRequest({
      method: 'POST',
      url: `/v2/users/register`,
      data: {
        ...input,
        type: UserType.journal,
        countryCode,
        region,
      },
    })

    authService.setUserData(userData)
    return userData
  }
  /**
   * End V2 API
   */
  async get() {
    const res = await api.makeRequest({
      method: 'GET',
      url: `/users/${authService.getUserId()}`,
    })

    authService.setUserData(res)
    return res
  }

  async createGuest() {
    const user = authService.getUserData()
    if (user) {
      return {
        newAccount: false,
      }
    }

    let countryData = null
    try {
      countryData = await geoLocationService.getCountryData()
    } catch (err) {
      console.log('ERROR: detect country', err)
    }

    const val = uuidv4()

    await this.createAccountTransitionGuest('/users', {
      email: `${val}@guest.com`,
      password: val,
      optinMarketingEmails: false,
      language: '',
      countryData,
      isGuest: true,
    })

    return {
      newAccount: true,
    }
  }

  async guestTransition(req) {
    return await this.createAccountTransitionGuest(
      '/users/guestTransition',
      req,
    )
  }

  async create(req) {
    return await this.createAccountTransitionGuest('/users', req)
  }

  async createAccountTransitionGuest(
    endpoint,
    {
      email,
      password,
      optinMarketingEmails,
      language,
      countryData = null,
      isGuest,
    },
  ) {
    let country = null,
      region = 'ROW'
    if (countryData) {
      const data = this.getCountryAndRegion(countryData)
      country = data.country
      region = data.region
    }

    let res
    try {
      res = await api.makeRequest({
        method: 'POST',
        url: endpoint,
        data: {
          email,
          password,
          optinMarketingEmails,
          language,
          country,
          region,
          isGuest,
        },
      })
    } catch (err) {
      // authService.clearUserData()
      throw err
    }

    authService.setUserData(res)
    return res
  }

  async login({ email, password }) {
    try {
      const res = await api.makeRequest({
        method: 'POST',
        url: `/users/auth`,
        data: {
          email,
          password,
        },
      })
      authService.setUserData(res)
      return res
    } catch (err) {
      authService.clearUserData()
      throw err
    }
  }

  async updateCountry(countryData) {
    const { country, region } = this.getCountryAndRegion(countryData)

    await ordersService.setOrderDetails({ country })
    // @ts-ignore
    await this.update({ country, region })

    return { country, region }
  }

  getCountryAndRegion(countryData) {
    let region = 'ROW'

    if (countryData.region === 'Europe') {
      region = 'EU'
    }

    if (countryData.alpha3Code === 'GBR') {
      region = 'UK'
    }

    if (countryData.alpha3Code === 'USA') {
      region = 'US'
    }

    console.log('Country / Region updated', countryData.alpha3Code, region)
    return {
      country: countryData.alpha3Code,
      region,
    }
  }

  async update({
    email,
    name,
    region,
    language,
    refferer,
    plan,
    is_parent,
    notifications_frequency,
    onboarding_completed,
    country,
  }) {
    const res = await api.makeRequest({
      method: 'PUT',
      url: `/users/${authService.getUserId()}`,
      data: {
        email,
        name,
        region,
        language,
        refferer,
        plan,
        is_parent,
        notifications_frequency,
        onboarding_completed,
        country,
      },
    })

    await helpersService.sleep(50)
    authService.updateUserData(res.user)
    return res
  }

  async updatePassword(oldPassword, newPassword) {
    const res = await api.makeRequest({
      method: 'PUT',
      url: `/users/${authService.getUserId()}/password`,
      data: {
        oldPassword,
        newPassword,
      },
    })

    authService.updateUserData(res.user)
    return res
  }

  async getByRegisterToken(token) {
    const res = await api.makeRequest({
      method: 'GET',
      url: `/users/token/${token}`,
    })

    return res.user
  }

  async requestPasswordReset(email) {
    const res = await api.makeRequest({
      method: 'POST',
      url: `/users/password/requestReset`,
      data: {
        email,
      },
    })
    return res.msg
  }

  async resetPassword(code, newPassword) {
    const res = await api.makeRequest({
      method: 'PUT',
      url: `/users/password/reset`,
      data: {
        code,
        newPassword,
      },
    })
    return res.msg
  }

  async getEmailNotifications() {
    const res = await api.makeRequest({
      method: 'GET',
      url: `/users/${authService.getUserId()}/notifications`,
    })

    return res
  }

  async getEmailNotificationsPreferences(
    userEmail = null,
    emailNotificationsToken = null,
  ) {
    if (!userEmail || !emailNotificationsToken) {
      return await api.makeRequest({
        method: 'GET',
        url: `/users/${authService.getUserId()}/notifications`,
      })
    }

    return await api.makeRequest({
      method: 'GET',
      url: `/users/email/notifications?userEmail=${userEmail}&emailNotificationsToken=${emailNotificationsToken}`,
    })
  }

  async updateEmailNotificationsPreferences(
    userEmail = null,
    emailNotificationsToken = null,
    preferences,
  ) {
    if (!userEmail || !emailNotificationsToken) {
      return await api.makeRequest({
        method: 'PUT',
        url: `/users/${authService.getUserId()}/notifications`,
        data: {
          ...preferences,
        },
      })
    }

    return await api.makeRequest({
      method: 'PUT',
      url: `/users/email/notifications?userEmail=${userEmail}&emailNotificationsToken=${emailNotificationsToken}`,
      data: {
        ...preferences,
      },
    })
  }

  async unsubscribe() {
    const res = await api.makeRequest({
      method: 'PUT',
      url: `/users/${authService.getUserId()}/unsubscribe`,
    })
    await authService.updateUser()
    return res
  }
}

export default new UserService()
