export default class ConsistencyManager {
	static getAlbumPages(albumId) {
		return ConsistencyManager._clone(ConsistencyManager[`ALBUM_${albumId}_PAGES`])
	}

	static setAlbumPages(albumId, pages) {
		pages = ConsistencyManager._clone(pages)
		pages = ConsistencyManager._cleanPages(pages)
		ConsistencyManager[`ALBUM_${albumId}_PAGES`] = pages
	}

	static clearAlbumPages(albumId) {
		delete ConsistencyManager[`ALBUM_${albumId}_PAGES`]
	}

	static clearAllAlbumsPages() {
		Object.keys(ConsistencyManager).forEach(key => {
			if (/^ALBUM_\d_PAGES/.test(key)) {
				delete ConsistencyManager[key]
			}
		})
	}

	static _cleanPages(pages) {
		if (pages) {
			return pages.map(p => {
				delete p.newlyAdded
				delete p.isSelected
				delete p.doneLoading
				return p
			})
		}
		return null
	}

	static _clone(o) {
		if (o) {
			return JSON.parse(JSON.stringify(o))
		}
		return null
	}
}