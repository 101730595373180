import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import geoLocationService from '../../services/geoLocation'
import { SIGN_UP_LOCATION } from '../../common/constants'
import { TrustWidget } from '../../components'
import FAMILY_IMG from '../../images/illustrations/family-amico.svg'
import ECO_IMG from '../../images/illustrations/volunteering-cuate.svg'
import TREMO_BOOK_IMG_3 from '../../images/showroom/book_13.jpg'
import TREMO_BOOK_IMG_1 from '../../images/showroom/book_17.jpg'
import TREMO_BOOK_IMG_2 from '../../images/showroom/book_18.jpg'
import TESTIMONIAL_1_AVATAR_IMG from '../../images/testimonials/paige_avatar.jpg'
import TESTIMONIAL_2_AVATAR_IMG from '../../images/testimonials/nicole_galloway_avatar.jpg'
import { Footer, LandingNavbar } from '../common'
import PricingService from '../pricing/PricingService'

const HeroSection = () => {
  return (
    <div className='header h-fullscreen'>
      <div className='container'>
        <div className='row align-items-center h-100'>
          <div className='col-lg-6'>
            <h1
              className='lead fw-900'
              style={{ fontSize: '26px', color: '#ecb4b4' }}
            >
              Empty Baby Journal? <br /><br />
              Never miss a moment anymore.
              <br />
            </h1>

            <p
              className='lead mt-5 mb-8'
              style={{ fontSize: '18px', fontWeight: 600 }}
            >
              ✔️ Tremo Books texts you smart reminders about your son or daughter daily.
              <br />
              <br />
              ✔️ Add your response, upload a photo and keep up with your baby record book.
              <br />
              <br />
              ✔️ Get a printed photo book every year.
            </p>

            <TrustWidget />

            <p className='gap-xy'>
              <Link
                to={SIGN_UP_LOCATION}
                className='btn btn-primary mw-200'
                style={{ borderRadius: '10px' }}
              >
                Create Baby Journal
              </Link>
            </p>
          </div>

          <div
            className='col-lg-5 ml-auto d-none d-lg-block'
            style={{ maxHeight: '500px' }}
          >
            <img
              src={FAMILY_IMG}
              alt='Keep your baby journal up to date.'
              style={{ maxWidth: '400px' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

// @paigeerica
const Testimonial1 = () => {
  return (
    <div className='section py-7' style={{ backgroundColor: '#ecb4b4' }}>
    <div className='container'>
      <div className='row gap-y align-items-center'>
        <div className='col-md-12 text-white'>
          <blockquote className="blockquote text-white mb-0 mt-0">
            <p className="text-left">They are amazing! Basically you are never allowed to close down your shop because I need one every year for the rest of my boys life.</p>
            <br />
            <div><img className="avatar avatar-xl" src={TESTIMONIAL_1_AVATAR_IMG} alt="Baby Book Maker" /></div>
            <footer className="text-white"><cite>Paige</cite></footer>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
  )
}

const FeaturesSection = () => {
  return (
    <div id='section-features' className='section'>
      <div className='container'>
        <div className='row gap-y align-items-center'>
          <div className='col-md-6 ml-auto'>
            <h4>Smart Reminders & Digital Journal</h4>
            <ol>
              <li>Get a daily SMS with a question about your child. Your response and photo is saved in your Tremo book.</li>
              <li>Save memories, milestones or just your child's daily routine on the fly. At any point you can go to your Tremo Journal and add more photos.</li>
              <li>Memories deserve to be printed and shared with the loved ones. Order a book each year or whenever you want, build out of your child's series of Tremo Books.</li>
            </ol>
            <p className='gap-xy'>
              <Link
                to={SIGN_UP_LOCATION}
                className='btn btn-primary mw-200'
                style={{ borderRadius: '10px' }}
              >
                Create Baby Record Book
              </Link>
            </p>
          </div>

          <div className='col-md-5 order-md-first'>
            <img
              className='landing-features-centered-image'
              src={TREMO_BOOK_IMG_3}
              alt='Easiest way to keep up with your baby journal.'
            />
          </div>
        </div>

        <hr className='my-8' />

        <div className='row gap-y align-items-center'>
          <div className='col-md-6 mr-auto'>
            <h4>Just Photo Books - Hardcover</h4>
            <p>
              Anyone can print photo books with Tremo, even if you don't use our baby journal service. We designed our Tremo Books to be the fastest way to create a photo book - get it done in under 10 minutes.
            </p>
            <p>
              Upload photos from your phone, tablet or laptop or straight from your Instagram. Forget about complicated options and clunky software.
            </p>
            <ul>
              <li>Square: 21 x 21 cm</li>
              <li>Premium finish. Extra-thick paper. Print quality 300dpi.</li>
              <li>Certified by the Sustainable Green Printing Partnership (SGP), the print industry’s gold standard in sustainability.</li>
              <li>Personalise the cover and spine</li>
              <li>20 pages included in starting price</li>
              <li>Maximum number of pages per book: 200</li>
            </ul>
            <p className='gap-xy'>
              <Link
                to={SIGN_UP_LOCATION}
                className='btn btn-primary mw-200'
                style={{ borderRadius: '10px' }}
              >
                Create Photo Book
              </Link>
            </p>
          </div>

          <div className='col-md-5'>
            <img
              className='landing-features-centered-image'
              src={TREMO_BOOK_IMG_1}
              alt='Square Lay Flat Photo Books - Premium Hardcover.'
            />
          </div>
        </div>

        <hr className='my-8' />

        <div className='row gap-y align-items-center'>
          <div className='col-md-6 ml-auto'>
            <h4>Instagram Photo Books</h4>
            <p>
              Tremo Book is the quickest and easiest way you can transform your Instagram feed into a top quality printed photo book. Our proprietary software takes your Instagram photos an gets them ready to be printed on top quality paper at best possible resolution.
            </p>
            <p className='gap-xy'>
              <Link
                to={SIGN_UP_LOCATION}
                className='btn btn-primary mw-200'
                style={{ borderRadius: '10px' }}
              >
                Create Instagram Photo Book
              </Link>
            </p>
          </div>

          <div className='col-md-5 order-md-first'>
            <img
              className='landing-features-centered-image'
              src={TREMO_BOOK_IMG_2}
              alt='Create Instagram Photo Books.'
            />
          </div>
        </div>

        <hr className='my-8' />

        <div className='row gap-y align-items-center'>
          <div className='col-md-6 mr-auto'>
            <h4>Green Printing</h4>

            <p>
              As a company working with paper, inks and oils, we are highly motivated to maximise our sustainability and set a high bar for environmental responsibility in our industry. Today, through our company-wide Zero Landfill initiative, more than 97 percent of waste is recycled or diverted from landfills and we are striving to reduce our landfill footprint to as close to zero as possible.
            </p>
            <p className='gap-xy'>
              <Link
                to={SIGN_UP_LOCATION}
                className='btn btn-primary mw-200'
                style={{ borderRadius: '10px' }}
              >
                Print Photo Album
              </Link>
            </p>
          </div>

          <div className='col-md-5'>
            <img
              className='landing-features-centered-image'
              src={ECO_IMG}
              alt='Ecological printed photo albums.'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

// @coco.loren
const Testimonial2 = () => {
  return (
    <div className='section py-7' style={{ backgroundColor: '#ecb4b4' }}>
    <div className='container'>
      <div className='row gap-y align-items-center'>
        <div className='col-md-12 text-white'>
          <blockquote className="blockquote text-white mb-0 mt-0">  
            <p className="text-left">When receiving this photo album book both my husband and I were truly blown away. The size is perfect and each page is thick with a gorgeous print that you can add captions in.</p>
            <br />
            <div><img className="avatar avatar-xl" src={TESTIMONIAL_2_AVATAR_IMG} alt="Baby Book Online Maker" /></div>
            <footer className="text-white"><cite>Nicole Galloway</cite></footer>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
  )
}

const PricingSection = ({ countryCode }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="section-header">
          <h2>Pricing</h2>
          <hr />
          <p className="lead">Designed to keep your memories safe and sound for many years.</p>
        </div>

        <div className="row">
          <div className="col-md-10 mx-auto">
            {/* <h6 className="divider">Horizontal 1</h6> */}

            <a className="row no-gutters pricing-4" href="#">
              <div className="col-md-9 plan-description">
                <h5>21 x 21 Premium Hardcover Photo Book</h5>
                <p className="mb-0">Premium finish. Extra-thick paper. Print quality 300dpi. Extra pages at {PricingService.formatPriceByCountry(PricingService.getExtraPagePriceCents(countryCode), countryCode)} each.</p>
              </div>

              <div className="col-md-3 plan-price">
                <h4>{PricingService.formatPriceByCountry(PricingService.getBaseBookPriceCents(countryCode), countryCode)}</h4>
                <p>20 pages</p>
              </div>
            </a>

            <br />

            <a className="row no-gutters pricing-4 popular" href="#">
              <div className="col-md-9 plan-description">
                <h5>Smart Reminders & Digital Journal</h5>
                <p className="mb-0">Get one smart question over SMS daily and unlimited journal entries. On top of this you will also get a 25% discount on all photo books.</p>
              </div>

              <div className="col-md-3 plan-price">
                <h4>{PricingService.formatPriceByCountry(PricingService.getJournalPriceCents(countryCode), countryCode)} <small>/ mo</small></h4>
                <p>one text per day</p>
              </div>
            </a>

            <br />
            <TrustWidget />
            
          </div>
        </div>
      </div>
    </div>
  )
}

/*
<blockquote className="blockquote text-white mb-0 mt-5">
  <p className="text-left" style={{ color: `#ecb4b4` }}>My daughter and I love looking through our album together and all our special memories.</p>
  <footer style={{ color: `#ecb4b4` }}><cite>Lisa</cite></footer>
</blockquote>

<h5 className='mt-5 mb-0 text-md-left' style={{ color: `#ecb4b4` }}>
  I usually have no patience for putting together photo books but this literally took about 5 clicks and I was done. The images are stunning and the book is super high quality.
  <br /><br />- Louise
</h5>

<h5 className='mt-5 mb-0 text-md-left' style={{ color: `#ecb4b4` }}>
  All my Instagram moments have been created into a beautiful keepsake that I shall treasure forever. I have displayed it in our living room and guests absolutely love it.
  <br /><br />- Marge
</h5>
*/

const LastCTA = () => {
  return (
    <div className='section'>
      <div className='container text-center'>
        <div className='section-header'>
          <h2>Join The Club - 14 days Free </h2>
          <hr />
          <p className='lead'>
            We won't charge you for 14 days. You can cancel your subscription at any point until then.
          </p>
        </div>

        <div className='row'>
          <div className='col-md-8 col-xl-6 mx-auto'>
            <Link
              to={SIGN_UP_LOCATION}
              className='btn btn-primary btn-xl mw-200'
              style={{ borderRadius: '50px' }}
            >
              Create Photo Book
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const LandingPage = (props) => {
  const [countryCode, setCountryCode] = useState(null)

  useEffect(() => {
    const loadCountryCode = async () => {
      try {
        const countryData = await geoLocationService.getCountryData()
        setCountryCode(countryData.alpha3Code)
      } catch (error) {
        setCountryCode('ROW')
      }
    }

    loadCountryCode()
  }, [])

  return (
    <>
      <LandingNavbar />
      <HeroSection />
      <Testimonial1 />
      <FeaturesSection />
      <Testimonial2 />
      <PricingSection countryCode={countryCode} />
      <LastCTA />
      <Footer />
    </>
  )
}
