import React from 'react'
import { Link } from 'react-router-dom'
import {
  BLOG_LOCATION,
  CONTACT_EMAIL,
  HOME_LOCATION,
  PRIVACY_LOCATION,
  REFUNDS_POLICY_LOCATION,
  SHIPPING_POLICY_LOCATION,
  TERMS_LOCATION,
} from '../../common/constants'
import LOGO_URL from '../../images/logo.png'

export const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='row gap-y align-items-center'>
          <div className='col-6 col-lg-3'>
            <Link to={HOME_LOCATION}>
              <img src={LOGO_URL} alt='logo' style={{ width: '30px' }} />
            </Link>
          </div>

          <div className='col-6 col-lg-3 text-right order-lg-last'>
            <div className='social'>
              <a
                className='social-instagram'
                href='http://instagram.com/tremo.books'
                target='_blank'
              >
                <i className='fa fa-instagram'></i>
              </a>
              <a
                className='social-facebook'
                href='https://www.facebook.com/tremo.books'
                target='_blank'
              >
                <i className='fa fa-facebook'></i>
              </a>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='nav nav-bold nav-uppercase nav-trim justify-content-lg-center'>
              {/* <Link className='nav-link' to={BLOG_LOCATION}>
                Blog
              </Link> */}
              {/* <Link className='nav-link' to={BLOG_LOCATION}>
                About
              </Link> */}
              <a className='nav-link' href={`mailto:${CONTACT_EMAIL}`}>
                Contact Us
              </a>
              <Link className='nav-link' to={PRIVACY_LOCATION}>
                Privacy Policy
              </Link>
              <Link className='nav-link' to={TERMS_LOCATION}>
                Terms of Use
              </Link>
              <Link className='nav-link' to={SHIPPING_POLICY_LOCATION}>
                Shipping Policy
              </Link>
              <Link className='nav-link' to={REFUNDS_POLICY_LOCATION}>
                Refunds Policy
              </Link>
              {/* <div className='nav-link'>@2020 Social Kat Limited</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
