import api from './api'
import authService from './auth'

// import SegmentAnalytics from 'analytics.js'
// import SegmentAnalytics from 'analytics-node'
// import { SEGMENT_KEY } from '../constants'
// const analytics = windo

let IDENTIFIED = false

class Analytics {
  constructor() {
    this.client = window.analytics
  }

  // Granular events tracked by our own database
  async addEvent(eventName, metadata = {}) {
    return await api.makeRequest({
      method: 'POST',
      url: `/users/${authService.getUserId()}/events`,
      data: {
        eventName, metadata
      }
    })
  }

  async identify(userId, data) {
    window.analytics.identify(userId, {
      ...data
    })
  }

  async track(event, properties) {
    console.log('Event', event, properties)
    console.log('Window analytics', window.analytics)
    window.analytics.track(event, properties, () => console.log('Callback active'))
  }

  async page(pageName) {
    window.analytics.page(pageName)
  }

  async cartViewed({ userId, productId, countPages, amount = null, currency = null, quantity = 1 }) {
    this.track('Cart Viewed', {
      user_id: userId,
      cart_id: userId,
      products: [{
        product_id: `${productId}_${currency}`,
        name: 'Tremo Book 8x8" Hardcover',
        variant: `${countPages} pages`,
        quantity: quantity
      }],
      revenue: amount / 100,
      currency: currency
    })
  }

  async checkoutStarted({ userId, productId, countPages, amount, currency, quantity = 1 }) {
    this.track('Checkout Started', {
      user_id: userId,
      cart_id: userId,
      // product_group: 'photobook_wrap_21x21_square',
      // content_type: 'product_group',
      // product: 'photobook_wrap_21x21_square',
      // product_group: 'photobook_wrap_21x21_square',
      revenue: amount / 100,
      currency: currency,
      products: [{
        product_id: `${productId}_${currency}`,
        name: 'Tremo Book 8x8" Hardcover',
        variant: `${countPages} pages`,
        quantity: 5
      }]
    })
  }

  async paymentInfoEntered({ userId, productId, countPages, amount, currency, quantity = 1 }) {
    this.track('Payment Info Entered', {
      user_id: userId,
      product_id: `${productId}_${currency}`,
      name: 'Tremo Book 8x8 Hardcover',
      variant: `${countPages} pages`,
      quantity: quantity,
      value: amount / 100,
      currency: currency
    })
  }

  async orderCompleted({ userId, orderId, productId, countPages, quantity, amount, currency, promoCode = null }) {
    this.track('Order Completed', {
      user_id: userId, 
      order_id: orderId,
      revenue: amount / 100,
      currency: currency,
      coupon: promoCode,
      products: [{
        product_id: `${productId}_${currency}`,
        name: 'Tremo Book 8x8" Hardcover',
        variant: `${countPages} pages`,
        quantity: quantity
      }],
      /*revenue: 45,
      shipping: 3,
      tax: 2,
      discount: 2.5,*/
    })
  }
}

export default new Analytics()
