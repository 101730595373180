import axios from 'axios'
import countries from '../helpers/countries'
import { GEO_PLUGIN_KEY } from '../constants'

class GeoLocation {
  async getCountryData() {
    try {
      const res = await axios({
        method: 'GET',
        url: `https://ssl.geoplugin.net/json.gp?k=${GEO_PLUGIN_KEY}`,
      })

      const alpha2Code = res.data.geoplugin_countryCode
      const countryData = countries.find(
        (value) => value.alpha2Code === alpha2Code,
      )

      return countryData
    } catch (err) {
      console.log('COUNTRY not detected')
      throw err
    }
  }

  async getRegion(country) {
    // switch (country) {
    //   case 'FR':
    //     return redirectBrowser('/fr')
    //     break
    //   case 'DE':
    //     return redirectBrowser('/de')
    //     break
    //   case 'IT':
    //     return redirectBrowser('/it')
    //     break
    //   case 'ES':
    //     return redirectBrowser('/es')
    //     break
    //   case 'GB':
    //     return redirectBrowser('/gb')
    //     break
    //   case 'US':
    //     return redirectBrowser('/us')
    //     break
    //   default:
    //     console.log('not a specific country, detect region.')
    // }
    // switch (countryData.region) {
    //   case 'Oceania':
    //     return redirectBrowser('/au')
    //     break
    //   case 'Europe':
    //     return redirectBrowser('/eu')
    //     break
    //   default:
    //     return redirectBrowser('/row')
    // }
  }
}

export default new GeoLocation()
