import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { LOGGED_IN_LOCATION, SIGN_UP_JOURNAL_LOCATION, SIGN_UP_PHOTO_BOOK_LOCATION } from '../../common/constants'
import { LogoCenter } from '../common'

export const Signup = (props) => {
  useEffect(() => {
    if (props.user) {
      props.history.push(LOGGED_IN_LOCATION)
    }
  }, [])

  return (
    <div className='container d-flex h-100 justify-content-center'>
      <div className="row justify-content-center align-self-center mt-5">
        <LogoCenter />
        
        <div className='col-12 bg-white rounded shadow-7 p-6 w-300 h-100 mt-5'>
          <div className='card d-block'>
            <div className='card-body'>
              <h5 className='card-title mb-5'>Choose Product</h5>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: '100%', marginBottom: '25px', height: '40px' }}
                onClick={() => props.history.push(SIGN_UP_JOURNAL_LOCATION)}
              >
                Smart Reminders & Digital Journal
              </Button>
              <p>Get smart SMS reminders daily to upload a new entry in your baby's digital journal. Order a photo book at any time.<b>25% discount on all photo books.</b></p>
              <p>*This is a monthly subscription service.</p>

              <hr />
              <Button
                variant="outlined"
                color="secondary"
                style={{ width: '100%',  marginBottom: '25px', height: '40px' }}
                onClick={() => props.history.push(SIGN_UP_PHOTO_BOOK_LOCATION)}
              >
                Photo Book Only 
              </Button>
              <p>Just interested in getting a one-off photo book without getting daily reminders about your son or daughter. No subscription needed.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}