export const AUTH_SESSION = 'AUTH_SESSION'
export const AUTH_CURRENT_USER = 'AUTH_CURRENT_USER'
export const DATE_FORMAT = 'MMMM Do, YYYY'
export const DATE_FORMAT_FULL = 'MMMM Do, YYYY HH:mm'
export const LOCAL_CONFIG = 'local_config'

export const CONTACT_EMAIL = 'contact@tremobooks.com'

export const HOME_LOCATION = '/'
export const BLOG_LOCATION = '/blog'
export const ABOUT_LOCATION = '/about'
export const PRIVACY_LOCATION = '/privacy-policy'
export const TERMS_LOCATION = '/terms-policy'
export const SHIPPING_POLICY_LOCATION = '/shipping-policy'
export const REFUNDS_POLICY_LOCATION = '/refunds-policy'

export const SIGN_UP_LOCATION = '/signup'
export const SIGN_UP_JOURNAL_LOCATION = '/signup/journal'
export const SIGN_UP_PHOTO_BOOK_LOCATION = '/create-my-book'
export const SIGN_UP_ADD_CARD_LOCATION = '/signup/card'
export const SIGN_UP_COMPLETED_LOCATION = '/signup/completed'
export const LOGIN_LOCATION = '/login'
export const LOGOUT_LOCATION = '/logout'
export const LOGGED_IN_LOCATION = '/books/active'
