import React from 'react'

export const TrustWidget = () => {
  return (
    <div className="landing-review-snipped">      
      <span className="iconbox text-white bg-warning" style={{ width: '24px', height: '24px', marginRight: '2px' }}>
        <i className="fa fa-star"></i>
      </span>
      <span className="iconbox text-white bg-warning" style={{ width: '24px', height: '24px', marginRight: '2px' }}>
        <i className="fa fa-star"></i>
      </span>
      <span className="iconbox text-white bg-warning" style={{ width: '24px', height: '24px', marginRight: '2px' }}>
        <i className="fa fa-star"></i>
      </span>
      <span className="iconbox text-white bg-warning" style={{ width: '24px', height: '24px', marginRight: '2px' }}>
        <i className="fa fa-star"></i>
      </span>
      <span className="iconbox text-white bg-warning" style={{ width: '24px', height: '24px', marginRight: '2px' }}>
        <i className="fa fa-star"></i>
      </span>

      <h6>98% Excellent</h6>
      <p>Trusted by over 2,000 parents</p>
    </div>
  )
}