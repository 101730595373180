import pricing from '../../helpers/pricing.json'

const functions = {
  getBaseBookPriceCents: (countryCode) => {
    return pricing?.[countryCode]?.base ?? pricing?.ROW?.base
  },
  getExtraPagePriceCents: (countryCode) => {
    return pricing?.[countryCode]?.extra ?? pricing?.ROW?.extra
  },
  getJournalPriceCents: (countryCode) => {
    return pricing?.[countryCode]?.journal ?? pricing?.ROW?.journal
  },
  getCurrencySymbolByCountry: (countryCode) => {
    return (
      pricing?.[countryCode]?.currencySymbol ?? pricing?.ROW?.currencySymbol
    )
  },
  formatPriceByCountry: (priceCents, countryCode) => {
    const currencySymbol = functions.getCurrencySymbolByCountry(countryCode)
    return `${currencySymbol}${priceCents / 100}`
  },
}

export default functions
