import eu from "../helpers/eu.json"
import others from "../helpers/others.json"

// Cyrilic script range
const cyrilicRegExp = /[\u0430-\u044F\u0410-\u042F]/g
// Chinese script range
const chineseRegExp = /[\u2E80-\u2FD5\u3400-\u4DBF\u4E00-\u9FCC]/g
// Korean script range
const koreanRegExp = /[\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF]/g
// Japanese script range
const japaneseRegExp = /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]/g
// Emoji range
const emojiRegExp = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu

const processText = (value, maxLength = 300, label = "caption") => {
  let error

  if (cyrilicRegExp.test(value)) {
    error = "Cyrillic characters are not supported"
    value = value.replace(cyrilicRegExp, "")
  }

  if (chineseRegExp.test(value)) {
    error = "Chinese characters are not supported"
    value = value.replace(chineseRegExp, "")
  }

  if (koreanRegExp.test(value)) {
    error = "Korean characters are not supported"
    value = value.replace(koreanRegExp, "")
  }

  if (japaneseRegExp.test(value)) {
    error = "Japanese characters are not supported"
    value = value.replace(japaneseRegExp, "")
  }

  if (emojiRegExp.test(value)) {
    error = "Emoji characters are not supported"
    value = value.replace(emojiRegExp, "")
  }

  if (value.length > maxLength) {
    value = value.substring(0, maxLength)
    error = `The ${label} cannot be more then ${maxLength} characters long`
  }

  return {
    value,
    error
  }
}

export default {
  sleep: async ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  },

  getMemoPublicUrl: memo => {
    if (!memo) {
      return null
    }

    if (memo.thumb_url && memo.public_thumb_url) {
      return memo.public_thumb_url
    }

    if (memo.url && memo.public_url) {
      return memo.public_url
    }

    return null
  },

  getCountryRegion: country => {
    if (country === "USA") {
      return "USA"
    } else if (country === "UK") {
      return "UK"
    } else {
      const isEu = eu.some(c => {
        return c.name === country
      })
      if (isEu) {
        return eu
      }
    }
    return "ROW"
  },

  processCaption: value => {
    return processText(value)
  },

  processText: processText,

  getFormattedPriceByRegion: (value = 0, region) => {
    let currencySymbol
    switch (region) {
      case "UK":
        currencySymbol = "£"
        break
      case "EU":
        currencySymbol = "€"
        break
      case "US":
        currencySymbol = "$"
        break
      default:
        currencySymbol = "$"
    }

    return `${currencySymbol}${value / 100}`
  },

  getFormattedPriceByCurrency: (value = 0, currency) => {
    let currencySymbol
    switch (currency && currency.toLowerCase()) {
      case "gbp":
        currencySymbol = "£"
        break
      case "eur":
        currencySymbol = "€"
        break
      default:
        currencySymbol = "$"
    }

    return `${currencySymbol}${value / 100}`
  },

  formatBytes: (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
